/* eslint-disable no-undef */
'use strict';

var eform = require('../../../app_apac_foundation/cartridge/js/eform');
var util = require('../../../app_apac_foundation/cartridge/js/util');

function initializeEvents() {
    function removeInvalidPostcode() {
        var postalEl = $('[name$="_eform_postal"]');
        if (postalEl.length) {
            if (postalEl.hasClass("error")) {
                postalEl.removeClass("error");
                if (postalEl.next().hasClass("error")) {
                    postalEl.next().remove();
                }
            }
        }
    }

    function updateRequireForAddress(required) {
        var listAddressName = ['_eform_postal'];
        var countryVal = $('[name$="_eform_country"]').val();
        for (var i in listAddressName) {
            $('[name$="' + listAddressName[i] + '"]').prop('required', required);
            if (required) {
                $('[name$="' + listAddressName[i] + '"]').addClass('required');
                if ($('[name$="' + listAddressName[i] + '"]').attr("placeholder")) {
                    $('[name$="' + listAddressName[i] + '"]').attr("placeholder", $('[name$="' + listAddressName[i] + '"]').attr("placeholder") + " *");
                }
                if ($('[name$="' + listAddressName[i] + '"]').parent().find('.required-indicator').length === 0) {
                    $('[name$="' + listAddressName[i] + '"]').parent().find('label').prepend('<span class="required-indicator"> * </span>');
                }            
            } else {
                $('[name$="' + listAddressName[i] + '"]').removeClass('required error');
                $('[name$="' + listAddressName[i] + '"]').parent().find('.required-indicator').remove();
                $('[name$="' + listAddressName[i] + '"]').parent().find('.error').remove();
                if ($('[name$="' + listAddressName[i] + '"]').attr("placeholder")) {
                    $('[name$="' + listAddressName[i] + '"]').attr("placeholder", $('[name$="' + listAddressName[i] + '"]').attr("placeholder").replace(" *", ""));
                }
            }
            $('[name$="_eform_country"]').val(countryVal).trigger('change');
        }
    }

    $(document).on('change', 'select[name$="_eform_addresstype"]', function () {
        var val = $(this).val();
        updateRequireForAddress(!!val);
    });

    $(document).on('change', 'select[name$="_eform_country"]', function () {
        util.updateStateOptions($('form[id$="dwfrm_eform"]'));
        $('[name$="_eform_states_state"]').prop('required', true);
        $('[name$="_eform_states_state"]').addClass('required');
        if ($('[name$="_eform_states_state"]').parent().find('.required-indicator').length === 0) {
            $('[name$="_eform_states_state"]').parent().find('label').prepend('<span class="required-indicator"> * </span>');
        }
        removeInvalidPostcode();
    });

    $('input[id$="_eform_address1"]').attr("readonly", true);
    $('select[id$="_eform_country"]').val("KR");
    $('select[id$="_eform_countrycode"]').attr("disabled", true);

}
module.exports = {
    init: function () {
        eform.init();
        initializeEvents();
    }
};