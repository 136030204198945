'use strict';

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */

var validateNumericPhone = function (value, el) {
    var nameRegEx = /^(\s*|\d+)$/;
    return nameRegEx.test(value);
};

var validatePhone = function (value, el) {
    var isOptional = this.optional(el);
    var isValid = false;
    var isValidPhone = false;
    var phoneInput = $('input[name$=_addressFields_phone]');
    var isValidPhoneIntl = phoneInput.intlTelInput('isValidNumber');
    var regex = /^[0]\d{10}$/;

    if (regex.test(value) && value !== null) {
        isValidPhone = true;
    }

    if (isValidPhone === true || isValidPhoneIntl === true) {
        isValid = true;
        phoneInput.val(value);
    }

    return isOptional || isValid;
};

$.validator.addMethod('checkNumericPhone', validateNumericPhone, Resources.REQUIRED_NUMBER);
$.validator.addMethod('checkPhone', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('phone', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('mobile', validatePhone, Resources.VALIDATE_PHONE);

var customizeValidatePhone = {
    init: function () {}
};

module.exports = customizeValidatePhone;
