'use strict';

var util = require('../../util');
var shipping = require('./shipping');
var couponCodekeyup = require('../../../../../app_rleu_core/cartridge/js/coupon-code');

function giftMessage() {
    $('.purchase-thisgiftMessage').on('click', function() {
        if ($(this).prop('checked')) {
        	$(this).parents('.gift-msg').find('.this-gift-message').removeClass('hide');
            $(this).parents('.gift-msg').find('.gift-checkbox-tooltip').removeClass('hide');
            $(this).prop('checked', true);
        } else {
            $(this).parents('.gift-msg').find('.this-gift-message').addClass('hide');
            $(this).parents('.gift-msg').find('.gift-checkbox-tooltip').addClass('hide');
            $(this).prop('checked', false);

            var url = util.appendParamsToUrl(Urls.removeGiftMessage, {
                'UUID': $(this).closest('.gift-msg').find('.save-this-gift-message').attr('value')
            });
            var curMessagefield = $(this).closest('.gift-msg').find('.this-gift-message input');
            $(this).closest('.gift-msg').find('span.char-count').find('.char-remain-count').html('0');
            $.ajax({
                url: url,
                callback: function (data) {
                    if (data.success) {
                        if (curMessagefield.length > 0)
                            curMessagefield.val('');
                    }
                }
            });
            $(this).closest('.gift-msg').find('.this-gift-message input').val('');
        }
    	if ($(this).closest('.gift-msg').find('.this-gift-message .gift-field .gift-Message-error').length > 0) {
    		$(this).closest('.gift-msg').find('.gift-message .gift-Message-error').remove();
    	}        
    	$(this).closest('tbody').find('.gift-field input').removeClass('customerror');
    	if ($(this).closest('tbody').find('.this-gift-message .gift-Message-error').length == 0 && $(this).closest('form').valid())  {
        	$('button[name$="chkoutButton"]').prop("disabled", false);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", false);    		
    	}
    });

    //uncheck other gift checkboxes if parent is unselected
    $('.purchase-thisgift').on('click', function () {
    	var url = '';
    	var lineItemUUID = $(this).closest('.gift-msg').find('.save-this-gift-message').attr('value');
        if (!$(this).prop('checked')) {
            var giftmsgchecked = $(this).closest('.gift-msg').find('.purchase-thisgiftMessage');
            var giftboxchecked = $(this).closest('.gift-msg').find('.purchase-thisgiftBox');

            if (giftmsgchecked.prop('checked')) {
                giftmsgchecked.trigger('click');
            }
            if (giftboxchecked.prop('checked')) {
                giftboxchecked.trigger('click');
            }
            url = util.appendParamsToUrl( Urls.selectProductAsGift, {
                removeThisGift:'true'
            });
        } else {
        	url = util.appendParamsToUrl( Urls.selectProductAsGift, {
                removeThisGift:'false'
            });
        } 
        url = util.appendParamsToUrl(url, {
            'UUID': lineItemUUID
        });
        $.ajax({
            url: url
        });
    });


    //This function will add the GiftBox to the LineItem
    $('.purchase-thisgiftBox').on('click', function() {
        var $this = $(this);
        var lineItemUUID = $this.val();
        var url = '';

        //If checked we are adding a GiftBox to the LineItem
        if ($this.prop('checked') == true) {
            url = Urls.addGiftBox;
            $this.parents('.gift-box').find('.gift-box-tooltip').removeClass('hide');
        } else {
            url = Urls.removeGiftBox;
            $this.parents('.gift-box').find('.gift-box-tooltip').addClass('hide');
        }

        url = util.appendParamsToUrl(url, {
            'lineItemUUID': lineItemUUID
        });

        $.ajax({
            url: url,
            dataType: 'html',
            callback: function() {},
            success: function(data) {
                if (JSON.parse(data).success) {
                    shipping.updateSummary();
                } else {
                    shipping.updateSummary();
                }
            },
            failure: function() {}
        });
    });

    $('.this-gift-message .purchase-giftmessage').on('focusout', function(e) {
        e.preventDefault();
        var flagValid = true;
        var country = window.Resources.COUNTRY_CODE;
        if (country && (country == 'TW' || country == 'KR')) {
            this.value = this.value;
        } else {
            this.value = this.value.replace(/[^\u0020-\u007F\u00A0-\u00FF\u20AC]/g, '');
        }

        if ($(this).closest('.this-gift-message').find('.save-this-gift-message').length > 0) {
            var url = $(this).closest('.this-gift-message').find('.save-this-gift-message').attr('href');
            var attributeName = '';
            if ($(this).hasClass('purchase-greeting')) {
                attributeName = 'greeting';
                flagValid = profanityCheck($(this).val(),$(this));
            } else if ($(this).hasClass('purchase-message1')) {
                attributeName = 'message1';
                flagValid = profanityCheck($(this).val(),$(this));
                
            } else if ($(this).hasClass('purchase-message2')) {
                attributeName = 'message2';
                flagValid= profanityCheck($(this).val(),$(this));
            } else if ($(this).hasClass('purchase-sender')) {
                attributeName = 'sender';
                flagValid = profanityCheck($(this).val(),$(this));
            }
            if (attributeName.length > 0) {
                url = util.appendParamsToUrl(url, {
                    'giftMessageVal': $(this).val(),
                    'customAttributename': attributeName
                });
                if (flagValid) {
                    $.ajax({
                        url: url
                    });
                }
            }
        }
    });
}

function applyCouponCode() {
    var $addCoupon = $('.add-coupon');
    if ($addCoupon.length == 0) {
        $addCoupon = $('#add-coupon');
    }

    $('.js-apply-coupon').off('click', '#add-coupon').on('click', '#add-coupon', function(e) {
        e.preventDefault();
        if ($('.remove-promo-code').length >= 3) {
            $('div.coupon-max').html(Resources.MAX_COUPON_CODE);
            return false;
        }
        var code = $(this).closest('.cart-coupon-code').find('[name$="_couponCode"]').val();
        var $couponCode = $(this).closest('.cart-coupon-code').find('[name$="_couponCode"]');
        var $codeundefined = $('input[name$="_couponCode"]');
        if (code === undefined && $codeundefined !== undefined) {
            code = $codeundefined.val();
            $couponCode = $codeundefined;
        }
        var multishipp = null;
        if (($('.multi-address').length > 0 && $('.multi-address').is(':checked')) || ($('.checkout-multi-shipping').length)) {
            multishipp = 'multishipp';
        }
        if (code.length === 0) {
            $couponCode.closest('.field-wrapper').append('<div class="coupon error">' + Resources.COUPON_CODE_MISSING + '</div>');
            setTimeout(function() {
                $couponCode.closest('.field-wrapper').find('.coupon').remove();
            }, 3000);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {
            couponCode: code,
            multishipp: multishipp,
            pagetype: $('div.cart-right-col').length > 0 ? 'cartPage' : null,
            format: 'ajax'
        });
        $.ajax({
            url: url,
            async: false,
            success: function(data) {
                var code = '',codeStatus = false ,codeLength = 0;
                var isGiftBoxPromoActive = $('<div />').append(data).find('#isGiftBoxPromoActive');
                if(window.location.pathname.indexOf('shipping') >= 0 && isGiftBoxPromoActive.length > 0 && isGiftBoxPromoActive.val()=='true') {
                	location.reload(); 
                }
                
                if (data != undefined && data.CouponCode == undefined) {
                    if ($('div.cart-right-col').length > 0 && $('div.cart-right-section-summary').length > 0) {
                        if ($(window).width() < 960) {
                            $('div.cart-right-section-summary').html(data);
                        } else {
                            $('div.cart-right-section-summary').html(data);
                        }
                    } else if ($('form.submit-order').length > 0 || $('input[name$="_billing_giftCertCode"]').length > 0) {
                        window.location.assign(Urls.billing)
                    } else {
                        $('div.apply-promo-code-summary').html(data);
                    }
                } else {
                    var fail = false;
                    var msg = '';
                    if (!data) {
                        msg = Resources.BAD_RESPONSE;
                        fail = true;
                    } else if (!data.success) {
                        msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                        fail = true;
                    }
                    if (fail) {
                        var promofield = $('[name$="_couponCode"]');
                        code = $(promofield).val();
                        codeStatus = true;

                        $('.coupon.error').remove();
                        $('.form-row.redemption.coupon').append($('<div class="coupon error">' + msg + '</div>'));
                        $('div.coupon-error').html(msg);
                        return;
                    }
                }
                if ($('.redemption').find('.success').length > 0) {
                    codeLength = $('.redemption').find('.success').length;
                    code = $('.redemption').find('.success')[codeLength - 1];
                    code = $(code).find('strong').text().trim();
                    codeStatus = false;
                }
                if ($('#cart-table').length > 0 && $('#cart-items-form').length > 0) {
                    refreshCart();
                }
                $('#add-coupon').prop('disabled', true);
           
                shipping.updateSummary();
                $('.add-coupon-toggle').hide();
                couponCodekeyup.couponCode();
            }
        });
    });
}

function removePromoCode() {
    var $removeCoupon = $('.remove-promo-code');
    $('.js-remove-coupon').off('click', $removeCoupon).on('click', $removeCoupon, function(e) {
        e.preventDefault();
        var url = util.appendParamsToUrl($(this).attr('href'), {
            format: 'ajax',
            pagetype: $('div.cart-wrapper').length > 0 ? 'cartPage' : null 
        });

        $.ajax({
            url: url,
            async: false,
            success: function(data) {
                var isGiftBoxPromoActive = $('<div />').append(data).find('#isGiftBoxPromoActive');
                if(window.location.pathname.indexOf('shipping') >= 0 && isGiftBoxPromoActive.length > 0 && isGiftBoxPromoActive.val()=='true') {
                	location.reload(); 
                }
                
                if (data != undefined && data.CouponCode == undefined) {
                    if ($('div.cart-right-col').length > 0 && $('div.cart-right-section-summary').length > 0) {
                        if ($(window).width() < 960) {
                            $('div.cart-right-section-summary').html(data);
                        } else {
                            $('div.cart-right-section-summary').html(data);
                        }
                    } else if ($('form.submit-order').length > 0 || $('input[name$="_billing_giftCertCode"]').length > 0) {
                        window.location.assign(Urls.billing)
                    } else {
                        $('div.apply-promo-code-summary').html(data);
                    }
                }
                applyCouponCode();
                removePromoCode();

                $('.mini-summary-btn').html($('.button-fancy-large').val());
                $('#add-coupon').prop('disabled', true);
                if ($('#cart-table').length > 0 && $('#cart-items-form').length > 0) {
                    refreshCart();
                }
                shipping.updateSummary();
                $('#add-coupon').prop('disabled', true);
                $('.add-coupon-toggle').hide();
                couponCodekeyup.couponCode();
            }
        });
    });
}

/**
 * Refresh Cart after ant update on cart page.
 * @returns
 */
function refreshCart() {
    var url = util.appendParamsToUrl(Urls.cartShow, {
        format: 'ajax'
    });
    $.ajax({
        url: url,
        async: false,
        dataType: 'html',
        success: function(data) {
            var $cartcouponcode = $('.cart-coupon-code');
            $('#cart-table').html($(data).find('#cart-table').html());
            if ($cartcouponcode.length > 0) {
                $('.cart-coupon-code').html($(data).find('.cart-coupon-code').html());
            }
            if ($('.atome-paragraph').length) {
                $('.atome-paragraph').html($(data).find('.atome-paragraph').html());
            }
            // cart.init();
            require.ensure([], function (require) {
                var chunk = require('../cart');
                if (chunk.init) {
                    chunk.init();
                }
            }, function (error) {
                console.log('error!', error);
            }, 'cart');

        }
    });
}

function profanityCheck(value, $this) {

    var profanityWords = SitePreferences.PROFANITY_WORDS;
    var profan = SitePreferences.PROFANITY_WORDS;

    if (profanityWords == undefined || profanityWords == 'null' || profanityWords == '' || value == '') {
        $this.closest('.gift-field').find('.gift-Message-error').remove();
        $this.removeClass('customerror');
        if ($('.gift-Message-error').length == 0 && $this.closest('form').valid()) {
            $('button[name$="chkoutButton"]').prop("disabled", false);
            $('button[name$="_shippingAddress_save"]').prop("disabled", false);
        }
        return true;
    }
    profanityWords = profanityWords.split('|');
    var valuecheck = value.toLowerCase().split(' ');
    for (var entval in valuecheck) {
        var profanArray = [];
        var entstr = valuecheck[entval].trim();
        for (var i = 0; i < profanityWords.length; i++) {
            var valData = profanityWords[i];
            if (entstr == valData) {
                $this.addClass('customerror');
                $this.closest('.gift-field').find('.gift-Message-error').remove();
                $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                $('button[name$="chkoutButton"]').prop('disabled', true);
                $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                return false;
            }

            if (profan.indexOf(entstr) >= 0) {
                var index1 = profan.indexOf(entstr);
                var indexlast = profan.lastIndexOf('|', index1);
                var index2 = profan.indexOf('|', index1);
                var profanity = profan.substring(indexlast + 1, index2);
                var sentence = '';

                if (profan.split(' ').length > 1) {
                    var loopindx = Number(entval) + profanity.split(' ').length;
                    for (var j = Number(entval); j < loopindx; j++) {
                        var check = valuecheck[j];
                        if (check != undefined && check != '') {
                            if (sentence.length == 0) {
                                sentence = check.trim();
                            } else {
                                sentence = sentence + ' ' + check.trim();
                            }
                        }
                        if (check == '') {
                            loopindx++;
                        }
                    }
                    if (valData == sentence) {
                        $this.addClass('customerror');
                        $this.closest('.gift-field').find('.gift-Message-error').remove();
                        $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                        $('button[name$="chkoutButton"]').prop('disabled', true);
                        $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                        return false;
                    }
                }
            }
            if (valData.split(' ').length > 1) {
                var profanword = valData.split(' ')[0];
                if (profanword == entstr) {
                    profanArray.push(valData);
                }
            }

        }
        if (profanArray.length > 0) {
            for (var k = 0; k < profanArray.length; k++) {
                var sentence1 = '';
                var profanityword = profanArray[k];
                var loopindx = Number(entval) + profanityword.split(' ').length;
                for (var l = Number(entval); l < loopindx; l++) {
                    var check = valuecheck[l];
                    if (check != undefined && check != '') {
                        if (sentence1.length == 0) {
                            sentence1 = check.trim();
                        } else {
                            sentence1 = sentence1 + ' ' + check.trim();
                        }
                    }
                    if (check == '') {
                        loopindx++;
                    }
                }
                if (profanityword == sentence1) {
                    $this.addClass('customerror');
                    $this.closest('.gift-field').find('.gift-Message-error').remove();
                    $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                    $('button[name$="chkoutButton"]').prop('disabled', true);
                    $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                    return false;
                }

            }
        }
    }
    $this.closest('.gift-field').find('.gift-Message-error').remove();
    $this.removeClass('customerror');
    if ($('.gift-Message-error').length == 0 && $this.closest('form').valid()) {
        $('button[name$="chkoutButton"]').prop("disabled", false);
        $('button[name$="_shippingAddress_save"]').prop("disabled", false);
    }

    return true;
};

exports.giftMessage = giftMessage;
exports.applyCouponCode = applyCouponCode;
exports.removePromoCode = removePromoCode;
