'use strict';

var shippingPage = {
    init: function () {
        $(document).ready(function() {
            var $formAddress = $('.checkout-shipping');
            var $state = $formAddress.find('[name$="_shippingAddress_addressFields_states_state"]');

            if ($state.length) {
                $state.prop("selectedIndex", 0).trigger('change');
            }

            $('body').on('click', 'div.show-add-address', function() {
                if ($state.length) {
                    $state.prop("selectedIndex", 0).trigger('change');
                }
            });
        });
    }
};

module.exports = shippingPage;
