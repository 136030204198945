'use strict';

var progress = require('../../../../../app_rleu_core/cartridge/js/progress'),
    dialog = require('../../dialog'),
    util = require('../../util'),
	validator = require('../../validator');

function initialize() {
	var isShipToStoreSelected = false,
		isDialogpositionTop = false;

	$(window).on('load', function(){
		var dynamicShippingForm = $('form.shipto-store-enabled');
		if(dynamicShippingForm && dynamicShippingForm.length > 0) {
			var phonefield = dynamicShippingForm.find('.phone'),
				phoneDiv = $(phonefield).closest('.input-wrapper'),
				selectedStoreWrapper = dynamicShippingForm.find('.shipto-store-address-details-wrapper'),
				storeAddress = selectedStoreWrapper.find('.shipto-store-address-details'),
				firstName= dynamicShippingForm.find('input[name$="_firstName"]').val(),
                lastName= dynamicShippingForm.find('input[name$="_lastName"]').val(),
                address1= dynamicShippingForm.find('input[name$="_address1"]').val(),
                stateCode= dynamicShippingForm.find('select[id$="_state"]').val(),
                postalCode= dynamicShippingForm.find('input[name$="_postal"]').val(),
                city= dynamicShippingForm.find('input[name$="_city"]').val(),
                suburbCode= dynamicShippingForm.find('select[name$="_city"]').val(),
                phone= dynamicShippingForm.find('input[name$="_phone"]').val();
			if (firstName == "" || lastName == "" || address1 == "" || stateCode == "" || postalCode == "" || (city == "" && suburbCode == "") || phone == "") {
				dynamicShippingForm.find('.button-fancy-large.shipping-address-click').prop('disabled',true); 
				$('.mini-summary-btn').prop('disabled',true);
			}
			if (!dynamicShippingForm.find('select[name$="_city"]').length) {
				$(phoneDiv).css('width','100%');
				dynamicShippingForm.find('.shipto-buttons-wrapper').insertAfter(phoneDiv).removeClass('hide');
			} else {
				var address2Field = dynamicShippingForm.find('input[name$="_address2"]'),
					address2Div = $(address2Field).closest('.input-wrapper');
				dynamicShippingForm.find('.shipto-buttons-wrapper').insertAfter(address2Div).removeClass('hide');
			}
			selectedStoreWrapper.insertAfter('.shipto-buttons-wrapper').removeClass('hide');
				//removing fields from DOM once we have Store address Ex: State, City,..
			if ($('.cartHasShipToStoreShipment').val() == 'true') {
				selectedStoreWrapper.nextAll('div.input-wrapper').hide().find('input, select').removeClass('error');
			}
		}
	});
	
	function selectStoreEvent(storeID, storeAddress, radius) {
		var storeAddressJSON = JSON.stringify(storeAddress);
		$('input[name$=_addressFields_address1]').val(storeAddress.address1);
		if (storeAddress.address2 != "") {
			$('input[name$=_addressFields_address2]').val(storeAddress.address2);
		}
        $('input[name$=_addressFields_city]').val(storeAddress.city);
        $('select[name$=_addressFields_states_state]').val(storeAddress.stateCode);
        $('input[name$=_addressFields_country]').val(storeAddress.countryCode);
		$('input[name$=postal]').val(storeAddress.postalCode);
		$('input[name$=_singleshipping_shipToUpsStore]').val(false);
		$('input[name$=_singleshipping_upsStoreData]').val("");
		if (storeAddress.ups) {
			$('input[name$=_singleshipping_shipToUpsStore]').val(true);
			$('input[name$=_singleshipping_upsStoreData]').val(storeAddressJSON);
		}

		var url = util.appendParamsToUrl(Urls.shipToStoreShipment, {
			storeID: storeID,storeAddressJSON: storeAddressJSON
		});

		$.ajax({
			url: url,
			type: 'POST',
			success: function(data) {
				var selShipToStoreWrapper = $('.shipto-store-address-details-wrapper'),
					storeAddress = selShipToStoreWrapper.find('.shipto-store-address-details');

				selShipToStoreWrapper.html($(data));
				isShipToStoreSelected = true;
				$('.findInStore-dialog .ui-icon-closethick').click();
				$('.shipto-store-address-details-wrapper .change-store-link').attr('data-radius', radius);
				$('.addToaddrBookShipping').hide();
            	//Updating Shipping method, Summary
				selShipToStoreWrapper.removeClass('hide');
				$('.cartHasShipToStoreShipment').val('true');
				var shipping = require('./shipping');
				shipping.updateShippingMethodList();
				shipping.updateSummary();
            	// removing other fields from DOM EX: State, City,..
				selShipToStoreWrapper.nextAll('div.input-wrapper').hide().find('input, select').removeClass('error');
			}
		});
	}
	
	$(document).on('change', '.shipto-store-wrapper input:radio',function() {
		progress.show();
		if ($('.address-select-box input[name$="_singleshipping_addressList"]').length > 0 && $('.address-select-box input[name$="_singleshipping_addressList"]').prop('checked') == true) {
			$('.address-select-box input[name$="_singleshipping_addressList"]').prop('checked','false');
		}

		var notShipToTitle = $(".not-shipto-title");

		if (notShipToTitle) {
			notShipToTitle.css("display", "none");
		}

		var populateZipcode = $('.pouplate-zipcode-value').val().trim().length > 0 ? $('.pouplate-zipcode-value').val() : '';
		var addresdFieldZipcode = $('#dwfrm_singleshipping_shippingAddress_addressFields_postal');
		if (addresdFieldZipcode.length > 0 && addresdFieldZipcode.val().length > 0) {
			populateZipcode = addresdFieldZipcode.val();
		} else if (populateZipcode.indexOf('-') > -1) {
			populateZipcode = populateZipcode.split('-')[0];
		}
		var $mixedcartbopisdetail = $('.mixed-cart-bopis-detail');
		if($mixedcartbopisdetail.length > 0 && $mixedcartbopisdetail.data('storeaddress') != null && SitePreferences.SHIPTO_RLSTORE_ENABLED) {
			var storeID = $mixedcartbopisdetail.data('storeid');
			var storeAddress = $mixedcartbopisdetail.data('storeaddress');
			var radius = 30;
			selectStoreEvent(storeID, storeAddress, radius);
		} else {
			openS2SModal(populateZipcode);
		}
    });
	function openS2SModal(populateZipcode,radius,mTitle) {
		var zipcode = populateZipcode,
			dialogPosition,
			windowWidth = $(window).width(),
			mobileDown = 667;	//Already we have FIS form fields design upto 666px for mobile mobile - following same
		var modalTitle = Resources.S2S_TITLE;
		if (mTitle != '' && mTitle != undefined) {
			modalTitle = mTitle;
		}
		if(windowWidth < mobileDown) {
			//For mobile - dialog set to top if we have zipcode and triggered search otherwise set to bottom
			dialogPosition = zipcode ? { my: "top", at: "top", of: window} : { my: "bottom", at: "bottom", of: window, collision: 'flipfit'}
		} else {
			dialogPosition = {  my: "top", at: "top+12%", of: window, collision: 'flipfit'};
		}
		dialog.open({
	        url: Urls.shipToStoreForm,
	        options: {
	            dialogClass: 'findInStore-dialog shipto-store-dialog',
	            title: modalTitle,
	            width: 'auto',
	            open: function(event, ui) {
	            	progress.hide();
	            	validator.init();
	            	getLocation();
	            	findInStoreAction();
                	var $searchKey = $('#pdp-storelocator').find('input[name="dwfrm_storelocator_searchKey"]');
                	if (radius != '' && radius != undefined) {
	            		$('[name$="_maxdistance"]').val(radius);
	            	} else if ($('.shipto-store-address-details-wrapper :empty').length == 0 && (radius == undefined || radius == '')) {
	            		$('[name$="_maxdistance"]').val('30');
	            	}
	            	// auto populate zipcode and search for Change Store link
	            	if(zipcode != '') {
	            		$searchKey.val(zipcode);
	               		$('button.find-store-button').trigger('click');
	               		isDialogpositionTop = true;
	            	}
	            	var thsDialog = $('.shipto-store-dialog');
	            	function dialogMaxHeight() {
	                	if($(window).width() < mobileDown && thsDialog.is(':visible')) {
	                		$("body").addClass('overflow-none');
	                		thsDialog.css('max-height',$(window).innerHeight());
	                	} else {
	                		$("body").removeClass('overflow-none');
	                		thsDialog.css('max-height','none');
	                	}
	            	}
	            	dialogMaxHeight();
	            	$(window).on('resize', function(){
	            		dialogMaxHeight();
	            	});
	            },
	            close: function() {
	            	//to update data or to select default radio button like Ship to one location
	            	if(isShipToStoreSelected == false) {
	            		location.reload();
	            	}
	            	if(windowWidth < mobileDown) {
	            		$("body").removeClass('overflow-none');
	            	}
	            },
	            draggable: false,
	            resizable: false,
	            position: dialogPosition
	        }
	    });
	}
	ShipToStoreCustomEvents();
	function ShipToStoreCustomEvents() {
		var fisWrapper = $('.findinstore-wrapper'),
			selectedFisWrapper = $('.findinstore-wrapper.selected');

		fisWrapper.find(".store-hours-wrapper .store-hours-title").on( "click", function() {
			$(this).closest('.findinstore-wrapper').find('.store-map-content').hide();
			$(this).next().slideToggle();
			$(this).toggleClass('openContent');
		});
		
		fisWrapper.find(".store-map-wrapper .store-map-title").on( "click", function() {
			$(this).closest('.findinstore-wrapper').find('.store-hours').hide();
			$(this).closest('.findinstore-wrapper').find('.store-map-content').slideToggle();
			$(this).toggleClass('openContent');
			var mapId = $(this).data('mapid');
			var latvalue = $(this).attr('data-lat');
			var lngvalue = $(this).attr('data-lng');
			var markerImage = $(this).data('marker');

			function gmapInit() {
				// pick center coordinates for your map
				var myMapCenter = { 
				             lat : parseFloat(latvalue), 
				             lng : parseFloat(lngvalue) 
				           };
				
				// create map and say which HTML element it should appear in
				var mapData = new google.maps.Map(document.getElementById(mapId), {
					center: myMapCenter,
					zoom: 14,
				    mapTypeControlOptions: {
				      mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
				    }, // here´s the array of controls
				    disableDefaultUI: false, // a way to quickly hide all controls
				    mapTypeControl: false,
				    streetViewControl: false,
				    scaleControl: false,
				    zoomControl: false,
				    zoomControlOptions: {
				      style: google.maps.ZoomControlStyle.LARGE 
				    },
				    mapTypeId: google.maps.MapTypeId.ROADMAP,
					styles: [
						  {
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#f5f5f5"
							      }
							    ]
							  },
							  {
							    "elementType": "labels.icon",
							    "stylers": [
							      {
							        "visibility": "off"
							      }
							    ]
							  },
							  {
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#616161"
							      }
							    ]
							  },
							  {
							    "elementType": "labels.text.stroke",
							    "stylers": [
							      {
							        "color": "#f5f5f5"
							      }
							    ]
							  },
							  {
							    "featureType": "administrative.land_parcel",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#bdbdbd"
							      }
							    ]
							  },
							  {
							    "featureType": "poi",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#eeeeee"
							      }
							    ]
							  },
							  {
							    "featureType": "poi",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#757575"
							      }
							    ]
							  },
							  {
							    "featureType": "poi.park",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#e5e5e5"
							      }
							    ]
							  },
							  {
							    "featureType": "poi.park",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#9e9e9e"
							      }
							    ]
							  },
							  {
							    "featureType": "road",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#ffffff"
							      }
							    ]
							  },
							  {
							    "featureType": "road.arterial",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#757575"
							      }
							    ]
							  },
							  {
							    "featureType": "road.highway",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#dadada"
							      }
							    ]
							  },
							  {
							    "featureType": "road.highway",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#616161"
							      }
							    ]
							  },
							  {
							    "featureType": "road.local",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#9e9e9e"
							      }
							    ]
							  },
							  {
							    "featureType": "transit.line",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#e5e5e5"
							      }
							    ]
							  },
							  {
							    "featureType": "transit.station",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#eeeeee"
							      }
							    ]
							  },
							  {
							    "featureType": "water",
							    "elementType": "geometry",
							    "stylers": [
							      {
							        "color": "#c9c9c9"
							      }
							    ]
							  },
							  {
							    "featureType": "water",
							    "elementType": "labels.text.fill",
							    "stylers": [
							      {
							        "color": "#9e9e9e"
							      }
							    ]
							  }
							]
				});

				  const beachMarker = new google.maps.Marker({
					    position: myMapCenter,
					    map: mapData,
					    icon: markerImage
					  });
			}
			
			if($(this).hasClass('openContent')){
				if (typeof google === 'object' && typeof google.maps === 'object') {
				     gmapInit();
				} else {
					var googleApiKey = $('#googleApiKey').text();
				     $.getScript('https://maps.googleapis.com/maps/api/js?key='+googleApiKey+'&', function(){
				         gmapInit();
				     });
				 }
			}


		});

		$(".close-fis-modal").on( "click", function() {
			$('.findInStore-dialog .ui-icon-closethick').click();
		});

		$('body').off('click','.change-store-link').on('click','.change-store-link', function(e) {
			e.preventDefault();
			progress.show();
			var zipcode = $(this).data('zipcode');
			var radius = $(this).data('radius');
			var title = Resources.S2S_CHANGE_STORE_TITLE;
			openS2SModal(zipcode,radius,title);
		});

		$('.shipto-store-dialog').off('click', 'button.ship-to-store-button').on('click', 'button.ship-to-store-button', function(e) {
			e.preventDefault();
			var storeID = $(this).data('storeid');
			var radius = $('[name$="_maxdistance"]').val();
			var storeAddress = $(this).data('storeaddress');
			selectStoreEvent(storeID, storeAddress, radius);
		});

	    //For selected store - button text is changed to SELECTED and removed inverse class to change background color
		if (fisWrapper.hasClass('selected')) {
			selectedFisWrapper.find('.shitp-to-store-form button').text(Resources.SELECTED_TEXT).removeClass('inverse');
		}
	}

	function getStoreList(e, element, $variation) {
		var $fisdialog = $('.findInStore-dialog'); 
		var $fiscontent = $('.findInStore-dialog #findinstore-content');
	    progress.show($fiscontent);
	    var storePage = 'shipping';
		var postalinput = $fisdialog.find('.zipcode');
		var postal = postalinput.val();
	    var radius = $fisdialog.find('select[id$="_storelocator_maxdistance"]').val();
	    var $filterElements = $fisdialog.find('input.filter-store-checkbox:checked');
	    var filterName = '';
	    if($filterElements.length == 1) {
	    	filterName = $filterElements.attr( "name" );
	    }
	    var url = util.appendParamsToUrl(Urls.gettoresforShipTOStore, {
	        postal: postal,
	        radius: radius,
	        storePage:storePage,
	        filter : filterName
	    });

	    $.ajax({
	        type: 'POST',
	        url: url,
	        success: function(response) {
	            if (response != null && response != undefined && response != "") {
	            	$fiscontent.html(response);
	                findInStoreAction();
	                ShipToStoreCustomEvents();
	                if($(window).width() < 667 && isDialogpositionTop == false) {
	        			//For mobile - dialog set to top once triggered search
	                	$fisdialog.find('#dialog-container').dialog( "option", "position", { my: "top", at: "top", of: window });
	        		}
	            }
	        }
	    });
	};
	
	var findInStoreAction = function() {
	    $('.findInStore-dialog').off('click', 'button.find-store-button').on('click', 'button.find-store-button', function(e) {
	        e.preventDefault();
	        $('#pdp-storelocator').validate().form();
	        var errorDiv = $('.empty-error');
	        if ($('.zipcode').val() != '') {
	        	if (errorDiv && errorDiv.length > 0){
	        		errorDiv.hide();
	        	}
	        	getStoreList(e, $(this));
	        } else {
	        	$('#findinstore-content').html('');
	        	errorDiv.text(Resources.S2S_EMPTY_TEXT).removeClass('hide').show();
	        }
	        
	    });
		
	    $('.findInStore-dialog').off('change', 'input.filter-store-checkbox').on('change', 'input.filter-store-checkbox', function(e) {
	        e.preventDefault();
	        $('#pdp-storelocator').validate().form();
	        var errorDiv = $('.empty-error');
	        var errorFilter = $('.filter-error');
	        var $filterElements = $('.findInStore-dialog').find('input.filter-store-checkbox:checked');
	        if ($filterElements.length == 0) {
	        	errorFilter.text(Resources.S2S_EMPTYFILTER_TEXT).removeClass('hide').show();
	        	$('.findinstore-wrapper').hide();
		    } else if ($('.zipcode').val() != '' && $filterElements.length > 0) {
	        	if (errorDiv && errorDiv.length > 0){
	        		errorDiv.hide();
	        	}
	        	if (errorFilter && errorFilter.length > 0){
	        		errorFilter.hide();
	        	}
	        	getStoreList(e, $(this));
	        } else {
	        	$('#findinstore-content').html('');
	        	errorDiv.text(Resources.S2S_EMPTY_TEXT).removeClass('hide').show();
	        }
	        
	    });
	};

	var getLocation = function() {
	    // If geolocation is available, try to get the visitor's position
	    if (navigator.geolocation) {
	        var title = Resources.FIS_CURRENT_LOCATION;
	        var $searchKeyWrapper = $('#pdp-storelocator').find('.dwfrm_storelocator_searchKey');
	        $searchKeyWrapper.append('<a href="javascript:void(0)" title="' + title + '"><i class="fa fa-location-arrow new-map-icon"></i></a>');
	        $searchKeyWrapper.off('click', '.fa-location-arrow').on('click', '.fa-location-arrow', function(e) {
	        	if (SitePreferences.SHIPTO_STORE_ENABLED) {
	        		function googleGeoLocation() {
		               	navigator.geolocation.getCurrentPosition(function(position) {
	                		var lat = position.coords.latitude;
	                    	var long = position.coords.longitude;
	                    	var point = new google.maps.LatLng(lat, long);
	                    	var $dialog = $('.findInStore-dialog');
	                    	$('#currentLatitude', $dialog).val(position.coords.latitude);
	                    	$('#currentLongitude', $dialog).val(position.coords.longitude);
	                       new google.maps.Geocoder().geocode({'latLng': point}, function (res, status) {
	                       	if (status == google.maps.GeocoderStatus.OK && res[0]) {
	                               for (var j = 0; j < res[0].address_components.length; j++) {
	                                   if (res[0].address_components[j].types[0] == 'postal_code'){
	                                   	$searchKeyWrapper.find('.zipcode').val(res[0].address_components[j].short_name);
	                                   	break;
	                                   }
	                               }
	                               // Trigger click find in store button when postal code gets populated.
	                               if ($searchKeyWrapper.find('.zipcode').val() != '') {
	                               	$('button.find-store-button').trigger('click');
	                               }
	                           } else {
	                               alert("Sorry, we are unable to find your location" + status);
	                           }
	                       });
	                	},function errorCallback(error) {
	                		switch(error.code) {
		       				case error.PERMISSION_DENIED:
		       					var errorDiv = $('.empty-error');
		       					$('#findinstore-content').html('');
		       					errorDiv.text(Resources.S2S_EMPTY_TEXT).removeClass('hide').show();
	                		}
	                    });
	        		}
	        		
	        		if (typeof google === 'object' && typeof google.maps === 'object') {
	        			googleGeoLocation();
					} else {
						var googleApiKey = $('#googleApiKey').text();
		          		$.getScript("//maps.googleapis.com/maps/api/js?key=" + googleApiKey + "&callback=storeMap").done(function(){
		          			googleGeoLocation();
		               }).fail(function(){
		               	alert("Sorry, we are unable to find your location" + status);
		               });
					 }
	        	} else {
	        		e.preventDefault();
	        		navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
	                $searchKeyWrapper.find('.zipcode').val(title).removeClass('error empty');	// IE workaround - removing empty class after setting value
	                $searchKeyWrapper.find('span.error').remove();
	        	}
	        });
	        $searchKeyWrapper.find('.zipcode').on('focus',function(){
	            if($(this).val().trim() == Resources.FIS_CURRENT_LOCATION){
	                $(this).val('');
	            }
	        });
	    }
	};

	// Define callback function for successful attempt
	var successCallback = function(position) {
	    var $dialog = $('.findInStore-dialog');
	    $('#currentLatitude', $dialog).val(position.coords.latitude);
	    $('#currentLongitude', $dialog).val(position.coords.longitude);
	    var $fiscontent = $('.findInStore-dialog #findinstore-content');
	    $fiscontent.html('');
	};

	// Define callback function for failed attempt
	var errorCallback = function(error) {
	    var $fiscontent = $('.findInStore-dialog #findinstore-content');
	    $('#pdp-storelocator').find('.zipcode').val('');
	    $fiscontent.html('<div class="error">' + Resources.FIS_ERROR_CURRENT_LOCATION + '</div>');
	};
}
exports.init = function () {
	initialize();
};