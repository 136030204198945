'use strict';

function AddressDialog($form, $editAddrForm, formLabel) {
    function markEmpty() {
        var $this = $(this);
        if ($this.val() == null || $this.val().length == 0) {
            $this.addClass('empty');
        } else {
            $this.removeClass('empty');
        }
    }

    function markAllAsValid($editAddrForm) {
        $editAddrForm.find('select').each(function () {
            $(this).addClass('valid');
        });
    }

    function onCancel() {
        closeDialog(this); // Passes DOM element through from handler call.
    }
    
    function closeDialog(element) {
        $(element).closest('.ui-dialog').find('.ui-dialog-titlebar-close').trigger('click');
    }

    function markSelectedAeState($editAddrForm, formLabel) {
        $editAddrForm.find('select[id$="_state"]').find('option').each(function (index, element) {
            if (element.value == 'AE') {
                $(element).prop('selected', element.text == formLabel);
            }
        });
        $editAddrForm.find('select[id$="_state"]').trigger('change');
    }

    function setFormValuesFromEditForm() {
        setFormValues({
            firstName: $editAddrForm.find('input[name$="_shippingaddress_firstName"]').val(),
            lastName: $editAddrForm.find('input[name$="_shippingaddress_lastName"]').val(),
            address1: $editAddrForm.find('input[name$="_shippingaddress_address1"]').val(),
            address2: $editAddrForm.find('input[name$="_shippingaddress_address2"]').val(),
            state: $editAddrForm.find('select[id$="_state"]').val(),
            city: $editAddrForm.find('[name$="_shippingaddress_city"]').val(),
            country: $editAddrForm.find('[name$="_shippingaddress_country"]').val(),
            postalCode: $editAddrForm.find('input[name$="_shippingaddress_postal"]').val(),
            stateOptionLabel: $editAddrForm.find('select[id$="_state"] option:selected').text()
        });
    }
    
    function setFormValues(address) {
        if (address.firstName) {
            $form.find('input[name$="_firstName"]').val(address.firstName);
        }
        if (address.lastName) {
            $form.find('input[name$="_lastName"]').val(address.lastName);
        }
        if (address.address1) {
            $form.find('input[name$="_address1"]').val(address.address1);
        }
        if (address.address2) {
            $form.find('input[name$="_address2"]').val(address.address2);
        }
        if (address.stateCode) {
            $form.find('select[id$="_state"]').val(address.stateCode);
        }
        if (address.state) {
            $form.find('select[id$="_state"]').val(address.state);
        }
        if (address.city) {
            $form.find('[name$="_city"]').val(address.city);
            if ((address.countryCode == "AU" || address.country == "AU" || address.countryCode == "MY" || address.country == "MY") && $('input[id="citySaved"]').length) {
                $('input[id="citySaved"]').val(address.city);
            }
        }
        if (address.postalCode) {
            $form.find('input[name$="_postal"]').val(address.postalCode).trigger('change');
        }
        if (address.country) {
            $form.find('input[name$="_country"]').val(address.country);
        } else if (address.countryCode) {
            $form.find('input[name$="_country"]').val(address.countryCode);
        }
        if (address.stateOptionLabel) {
            $form.find('select[id$="_state"]').find('option').each(function (index, element) {
                if (element.value == 'AE') {
                    $(element).prop('selected', element.text == address.stateOptionLabel);
                }
            });
        }
    }

    function apply() {
        if ($editAddrForm.valid()) {
            setFormValuesFromEditForm();
            closeDialog(this); // Bound as event handler below.
            $form.find('select[id$="_state"]').trigger('change');
            $('div.dyn-form-shipping').removeClass('hide');
        }
    }
    
    function useSuggested() {
        var card = $editAddrForm.find('.miniaddress.suggestion');
        var address = card.data('address');
        setFormValues(address);
        closeDialog(this); // Bound as event handler below.
        $form.find('select[id$="_state"]').trigger('change');
        $('div.dyn-form-shipping').removeClass('hide');
    }
    
    function skipSuggested() {
        isExperianEnabled = false;
        $('button[name$="_singleshipping_shippingAddress_save"]').trigger("click");
        closeDialog(this);
    }
    
    function checkFieldsChange() {
	    $editAddrForm.find('input, select').on("change input", function(){
	    	$('#dialogApplyBtn').show();
            // if (!$editAddrForm.find('.address-verification-tab').length) {
            //     $('#dialogSkipVerificatinBtn').hide();
            // }
		});
    }
    
    function showEditTab() {
        $editAddrForm.find('.address-edit-tab').show();
        $editAddrForm.find('.address-verification-tab').hide();
    }
    
    this.init = function () {
        markAllAsValid($editAddrForm);
        $('.input-text, .input-textarea').on('change blur input', markEmpty);
        markSelectedAeState($editAddrForm, formLabel);
        checkFieldsChange();
        $editAddrForm.off('click', '#dialogCancelBtn').on('click', '#dialogCancelBtn', onCancel);
        $editAddrForm.off('click', '#dialogApplyBtn').on('click', '#dialogApplyBtn', apply);
        $editAddrForm.off('click', '#dialogConfirmBtn').on('click', '#dialogConfirmBtn', useSuggested);
        $editAddrForm.off('click', '#dialogSkipVerificatinBtn').on('click', '#dialogSkipVerificatinBtn', skipSuggested);
        $editAddrForm.off('click', '.edit-button').on('click', '.edit-button', showEditTab);
    };
}

module.exports = AddressDialog;