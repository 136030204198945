'use strict';

var enhancedSignin = SitePreferences.ENNANCED_SIGNIN ? require('@apac/js/pages/enhancedSignin/accountlogin') : null;

function updateMonth(month, iday) {
    var Day = $('#dwfrm_profile_customer_day').val();
    var endday = 31;
    var selectedMonth = parseInt(month, 10);
    var el = iday;

    el[0].options.length = 31;

    if (selectedMonth === 2) {
        endday = 29;
        el[0].options.length = 29;
    }

    for (var i = 1; i <= endday; i++) {
        var option = new Option(i, i, true, false);
        el[0].options[i] = option;
    }

    if (selectedMonth === 2 && Day > 29) {
        $('#dwfrm_profile_customer_day').val(1).trigger('change');
    }else {
        $('#dwfrm_profile_customer_day').val(Day).trigger('change');
    }
    $('#dwfrm_profile_customer_day').val(Day).trigger('change');
}

var registerPage = {
    init: function () {
        $(document).on('click', '.kr-terms .view-terms', function () {
            var $this = $(this);
            var contentAssets = $("#dialog-registration-term").find('.dialog-registration-term-wrapper');
            var $data;
            if(contentAssets && contentAssets.length) {
                contentAssets.addClass('hide');
                if ($this.hasClass('consent-mkt-adv')) {
                    $('#dialog-registration-term .consent-mkt-adv').removeClass('hide');
                } else if ($this.hasClass('consent-transfer-provision')) {
                    $('#dialog-registration-term .consent-transfer-provision').removeClass('hide');
                } else if ($this.hasClass('consent-use-person')) {
                    $('#dialog-registration-term .consent-use-person').removeClass('hide');
                }
                if (SitePreferences.ENNANCED_SIGNIN) {
                    $data = $("#dialog-registration-term .dialog-registration-term-wrapper:not(.hide)").clone(true);
                        enhancedSignin.modal.open($data,'registration-term-modal');
                } else{
                    $("#dialog-registration-term").dialog({
                        modal: true,
                        resizable: false,
                        width: '80%'
                    });
                }
            }
        });
        $(document).on('change', '.kr-terms input[name$="_profile_customer_addtoemaillist"]', function () {
            var $this = $(this);
            var inputID = $(".sub-termcondition").find("input");
            var addToEmailListValue = $this.is(":checked");
            for (let item of inputID) {
                $(item).val(addToEmailListValue);
            }
        });

        $(document).on('change', '.kr-terms input[name$="_acceptAllTermAndConditions"]', function () {
            var $this = $(this);
            var checked = false;
            if ($this.prop('checked')) {
                $('.kr-terms .input-checkbox:not(".sub-termcondition-item")').prop('checked', true);
                checked = true;
            } else {
                $('.kr-terms .input-checkbox:not(".sub-termcondition-item")').prop('checked', false);
            }
            $('.kr-terms input[name$="_profile_customer_addtoemaillist"]').prop('checked', checked).trigger('change');
        });

        $(document).on('change', '.confirmation.create-acct-form #dwfrm_profile_customer_month', function () {
            var month = $('.confirmation.create-acct-form #dwfrm_profile_customer_month').val();
            var iday = $('.confirmation.create-acct-form #dwfrm_profile_customer_day');
            updateMonth(month, iday);
        });


        $(function() {
            if($('.register-container')) {
                if($('input[name$="_profile_login_acceptAllTermAndConditions"]').is(":checked") && !$('input[name$="_profile_login_RalphLaurenTermsAndConditions"]').is(":checked")) {
                    $('input[name$="_profile_login_RalphLaurenTermsAndConditions"]').trigger('click');
                    if ($('input[name$="_profile_customer_addtoemaillist"]').is(":checked")) {
                        var inputID = $(".sub-termcondition").find("label");
                        for (let item of inputID) {
                            if (item.control.checked) {
                                item.setAttribute("aria-checked", true);
                            } else {
                                item.setAttribute("aria-checked", false);
                            }
                        }
                    }
                }
            }
        })

    }
};

module.exports = registerPage;
