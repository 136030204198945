'use strict';

var findInStorePage = {
    init: function () {
        var $radius = $('.input-select[name$="_maxdistance"]');

        if ($radius.length > 0 && $radius.val()) {
            $radius.trigger('focusout');
        }
    }
};

module.exports = findInStorePage;
