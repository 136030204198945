'use strict';

var billingPage = {
    init: function () {
        $(document).on('click', '.show_billing_add_address, .edit-this-address, label[for$="_useAsBillingAddress"]', function () {
            var $formAddress = $('.checkout-billing');

            $formAddress.find('input[name$="_postal"]').closest('.form-row').find('label').first().html('<span class="required-indicator"> * </span>' + Resources.POST_CODE);
        });

        $(document).on('change', 'input[name$="_useAsBillingAddress"]', function () {
            var $formAddress = $('.dyn-form-billing');

            $formAddress.find('input[name$="_postal"]').closest('.form-row').find('label').first().html('<span class="required-indicator"> * </span>' + Resources.POST_CODE);
        });

        $(document).ready(function() {
            var $formAddress = $('.checkout-billing');

            $formAddress.find('input[name$="_postal"]').closest('.form-row').find('label').first().html('<span class="required-indicator"> * </span>' + Resources.POST_CODE);

            var $country = $formAddress.find('[name$="_billingAddress_addressFields_country"]');

            $country.attr('autocomplete', 'address-level1');
        });
    }
};

module.exports = billingPage;
