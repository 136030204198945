'use strict';

var profilePage = {
    init: function () {
        $(document).on('click', '.address-edit, .address-create', function () {
            $('.ui-dialog').trigger('focus');
        });

        $(document).on('focus', '.ui-dialog', function() {
            if (!$(this).find('#edit-address-form').length) {
                return;
            }

            setTimeout(function() {
                var $formAddress = $('#edit-address-form');

                $formAddress.find('input[name$="_postal"]').closest('.form-row').find('label').first().html('<span class="required-indicator"> * </span>' + Resources.POST_CODE);
            }, 100);
        });
    }
};

module.exports = profilePage;
