'use strict';

var limitCharacterName = function(value) {
    return encodeURI(value).split(/%..|./).length - 1 <= 48;
};

var validateFirstName = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3a-zA-Z\s\'\-]{1,24}$/;
    if ($('.checkout-shipping').find('.dyn-form-shipping').length == 0 && $('.checkout-billing').find('.dyn-form-billing').length == 0 && $('#edit-address-form').find('.form-group').length == 0) {
        nameRegEx = /^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z\s\'\-]{1,24}$/;
    }
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

var validateLastName = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3a-zA-Z0-9\'\-\s]{1,24}$/;
    if ($('.checkout-shipping').find('.dyn-form-shipping').length == 0 && $('.checkout-billing').find('.dyn-form-billing').length == 0 && $('#edit-address-form').find('.form-group').length == 0) {
        nameRegEx = /^[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z0-9\'\-\s]{1,24}$/;
    }
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

var cardexpdateValidation = function (value, el) {
    var isValid = false;
    // Since class name 'month' is same for both profile and payment to avoid css issues handling in client js
    var isFromProfile = $(el).closest('.pt_account').length > 0;
    var isRegisterForm = $(el).closest('.pt_account_guest').length > 0;
    var isFromOrderConfirmation = $(el).closest('.pt_order-confirmation').length > 0;
    var isEmployeePage = $(el).closest('.pt_employee').length > 0;
    var isEFromPage = $(el).closest('.pt_eform').length > 0;
    var isFromWishlist = $(el).closest('.wishlist-favorites').length > 0;
    var isLoginModal = SitePreferences.ENNANCED_SIGNIN && $(el).closest('.account-login-register-modal').length > 0;
    if(isFromProfile || isEmployeePage || isFromOrderConfirmation || isEFromPage || isFromWishlist || isRegisterForm || isLoginModal) {
    	return true;
    }
    var checkDayValid = validateDayOfMonth(value, el);
    if (!checkDayValid) {
        return true;
    }
	var expMonth = $(el).closest('form').find('[name$="_expiration_month"]').val();
	var expYear =  $(el).closest('form').find('[name$="_expiration_year"]').val();
    var currentYear = parseInt(new Date().getFullYear().toString());
    var currentMonth = parseInt(new Date().getMonth()+1);

    if ((expYear == currentYear && expMonth < currentMonth)){    	
    	isValid = false;
    } else if((expYear > currentYear) || (expYear == currentYear && expMonth >= currentMonth)){    	
    	isValid = true;
        $(el).closest('form').find('[name$="_expiration_year"]').removeClass('error');
        $(el).closest('form').find('[name$="_expiration_month"]').removeClass('error');
        $(el).closest('form').find('[name$="_expiration_year"]').next('span.error').hide();
        $(el).closest('form').find('[name$="_expiration_month"]').next('span.error').hide();
    }    
    return isValid;
};

var dateValidation = function (value, el) {
    var yearBirthdayElement = $(el).closest('form').find('input[name$="_customer_year"]');
    if(yearBirthdayElement) {
        return validateYear(value, el);
    }else{
        return cardexpdateValidation(value, el);
    }
};

var validateDayOfMonth = function(value, el) {
    var isValid = false;

    var yearBirthdayElement = $(el).closest('form').find('input[name$="_customer_year"]');
    var monthBirthdayElement = $(el).closest('form').find('select[name$="_customer_month"]');
    var dayBirthdayElement = $(el).closest('form').find('select[name$="_customer_day"]');
    if (yearBirthdayElement.length > 0 && monthBirthdayElement.length > 0 && dayBirthdayElement.length > 0) {
        var currentYear = new Date().getFullYear();

        var yearBirthday = yearBirthdayElement.val();
        var monthBirthday = monthBirthdayElement.val();
        var dayBirthday = dayBirthdayElement.val();
        if(isNaN(dayBirthday) || Number(dayBirthday) == 0 || Number(dayBirthday) > 31) {
            return false;
        }
        if(dayBirthday.toString().length > 0 && monthBirthday.toString().length > 0 && yearBirthday.toString().length > 0) {
            if(yearBirthday && !isNaN(yearBirthday) && Number(yearBirthday) > 1800 && Number(yearBirthday) < Number(currentYear)) {
                var isLeadYeap = leapYear(Number(yearBirthday));
                var listMonth30days = [4,6,9,11];
            
                if((!isLeadYeap && monthBirthday == 2 &&  dayBirthday > 28) || (listMonth30days.includes(Number(monthBirthday)) && dayBirthday == 31)) {
                    return isValid;
                }
            }
        }
    }
    
    return !isValid;
};

function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

var validateYear = function(value, el) {
    var isValid = false;
    var currentYear = new Date().getFullYear();

    var yearBirthdayElement = $(el).closest('form').find('input[name$="_customer_year"]');

    if (yearBirthdayElement.length > 0) {
        var yearBirthday = yearBirthdayElement.val();
        if(yearBirthday.toString().length > 0) {
            if(isNaN(yearBirthday) || Number(yearBirthday) < 1800 || Number(yearBirthday) > Number(currentYear)) {
                return isValid;
            }
        }
    }

    return !isValid;
};

$.validator.addMethod('limitcharacternamecheck', limitCharacterName, Resources.VALIDATE_MAXLENGTH);
$.validator.addMethod('firstName', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastName', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('firstname', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastname', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('year', dateValidation, Resources.VALIDATE_DATE);
$.validator.addMethod('month', cardexpdateValidation, Resources.VALIDATE_DATE);

var validator = {
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    }
};

module.exports = validator;