'use strict';

var ajax = require('@apac/js/ajax'),
    minicart = require('@apac/js/minicart'),
    util = require('@apac/js/util'),
    aep = require('@foundation/js/aepdatacollection-util');

var setAddToCartHandler = function (e) {
    e.preventDefault();
    //Before adding VGC to card Must Check Max VGC amount.
    var doAddTocart = true;
    var form = $(this).closest('form');
    var $button = $(this);
    if (!form.valid()) {
        $button.prop('disabled', true);
        return false;
    }
    var deliveryDateInput = form.find('input[name $= "purchase_deliveryDate"]');
    var deliveryDateVal = deliveryDateInput.val();
    var deliveryDateArray = deliveryDateVal.split(/\//);
    var newDeliveryDate = [deliveryDateArray[1], deliveryDateArray[2], deliveryDateArray[0]].join('/');
    deliveryDateInput.val(newDeliveryDate);
    var amountInput = form.find('input[name $= "purchase_gcAmount"]');
    var amount = amountInput.val();
    if (amount.endsWith('.00') || amount.endsWith(',00')) {
        amount = amount.slice(0, -3);
    }
    amount = amount.replace('₩', '').replace(/(,)*/g, '');
    amountInput.val(amount);

    $.ajax({
        dataType: 'json',
        url: Urls.vgcMaxAmount,
        type: 'GET',
        async: false
    }).done(function (response) {
        var totalAmount = parseInt(amount) + response.amount;
        //Do not consider previous GC amount
        if ($('.oldGCValue').length > 0) {
            totalAmount -= Number($('.oldGCValue').val());
        }
        var maxCartLimit = Resources.GIFT_CERT_CART_MAX_LIMIT || 500000;
        if (response != null && response != undefined && totalAmount > maxCartLimit) {
            form.find('.max-Amount-Msg').removeClass('hide');
            form.find('input[name$= "purchase_gcAmount"]').addClass('error1');
            $('html, body').animate({
                scrollTop: $('.product-col-2').offset().top
            }, 'slow');
            $button.prop('disabled', true);
            doAddTocart = false;
        } else {
            form.find('.max-Amount-Msg').addClass('hide');
            form.find('input[name$= "purchase_amount"]').removeClass('error1');
        }

        // Split Gift Message
        var charsLimit = $('#gift-card-message').data('character-counter');
        var charsLimitline = charsLimit / 4;
        var giftMessage = $('#gift-card-message').val();
        var giftMessageSplit = giftMessage.match(new RegExp('.{1,' + charsLimitline + '}(.\|$)', 'g'));
        var giftMessageLines = $('.gift-card-message input');
        $('.gift-card-message input').val('');
        if (giftMessage) {
            for (var i = 0; i < giftMessageSplit.length; i++) {
                $(giftMessageLines[i]).val(giftMessageSplit[i]);
            }
        }
    });

    if (doAddTocart) {

        var url = util.ajaxUrl(form.attr('action'))
        var options = {
            url: url,
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function (response) {
            if (response.success) {
                ajax.load({
                    url: Urls.minicartGC,
                    data: {
                        lineItemId: response.result.lineItemId
                    },
                    callback: function (response) {
                        $('#AddToBasketButton').html(Resources.Item_Add_To_Bag);
                        minicart.show(response, form, $button);
                        form.find('input,textarea').val('');
                        //Once GC is updated remove oldGCValue
                        $('.isGCUpdate').val('');
                        $('.oldGCValue').val('');
                        $('.gc-amounts a').removeClass('selected');
                        $('.js-gift-card-msg .input-checkbox').prop('checked', false);
                        $('.gift-card-message ').addClass('hide');
                        if ($('#dwfrm_giftcert_purchase_deliveryDate').length > 0) {
                            var today = new Date();
                            today = today.getFullYear() + '/' + (today.getMonth() + 1).toString().padStart(2, '0') + '/' + today.getDate().toString().padStart(2, '0');
                            $('#dwfrm_giftcert_purchase_deliveryDate').val(today);
                        }
                    }
                });
                form.find('span.error').remove();
                var $charcountclear = $('.gift-card-message').find('.gift-field');
                $charcountclear.find('input').val('');
                $charcountclear.find('span.char-count').find('.char-remain-count').html('0');
                aep.coreCall('addToCart', form);
            } else {
                form.find('span.error').hide();
                if (response.errors && response.errors.FormErrors) {
                    for (var id in response.errors.FormErrors) {
                        var $errorEl = $('#' + id).addClass('error').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', id + '-error').next('.error');
                        var $errorEltext = response.errors.FormErrors[id].replace(/\\'/g, '\'');

                        if (!$errorEl || $errorEl.length === 0) {
                            $errorEl = $('<span id="' + id + '-error" class="error" style=""></span>');
                            $('#' + id).after($errorEl);
                            //form.find('input[name $= "purchase_amount"]').focus();
                            $('html, body').animate({
                                scrollTop: $('.product-detail-section').offset().top
                            }, 'slow');
                        }
                        $errorEl.text($errorEltext).show();
                    }
                }
            }
        }).fail(function (xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    }
};

var checkBalance = function (e) {
    e.preventDefault();
    var form = $(this).closest('form');
    form.validate();
    if (!form.valid()) {
        return false;
    }
    var giftCardNumber = $('input[name$="_balance_giftCertID"]').val();
    form.find('.balance-result').html('');
    var options = {
        url: util.ajaxUrl(form.attr('action')),
        method: 'POST',
        cache: false,
        data: form.serialize()
    };
    $.ajax(options).done(function (response) {
        var balance = 0;
        var status = false;
        if (response.code == 'ERROR' || response.responsecode == 10) {
            form.find('.balance-result').html(Resources.GIFT_CERT_INVALID).addClass('error');
        } else {
            var formattedBalance = response.balance.replace(Resources.CURRENCY_CODE, '');
            if (formattedBalance.endsWith('.00')) {
                formattedBalance = formattedBalance.slice(0, -3);
            }
            formattedBalance = formattedBalance.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + Resources.CURRENCY_CODE;
            form.find('.balance-result').html(Resources.GIFT_CERT_BALANCE + ' ' + formattedBalance);
            status = true;
            if (response.balance != undefined)
                balance = response.balance;
            if (response.code != 'OK') {
                form.find('.balance-result').addClass('error');
            } else {
                form.find('.balance-result').removeClass('error');
            }
        }

        aep.coreCall('giftCardApply', giftCardNumber, status, balance);

    });
};

var giftCardPage = {
    init: function () {

        $('.deliveryDate,.dwfrm_giftcert_purchase_deliveryDate').datepicker('option', {
            dateFormat: 'yy/mm/dd',
            monthNames: ["1월", "2월", "3월", "4월", "5월", "6월",
                "7월", "8월", "9월", "10월", "11월", "12월"],
            monthNamesShort: ["1월", "2월", "3월", "4월", "5월", "6월",
                "7월", "8월", "9월", "10월", "11월", "12월"],
            dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
            dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"]
        });

        $('.dwfrm_giftcert_purchase_deliveryDate.hasDatepicker + .ui-datepicker-trigger').remove();

        $('#dwfrm_giftcert_purchase_deliveryDate').off('click change keyup mouseup blur').on('click change keyup mouseup blur', function () {
            var selectedDate = $(this).val();
            var date = new Date(selectedDate.split('/')[0] + '/' + selectedDate.split('/')[1] + '/' + selectedDate.split('/')[2]);
            var currentDate = new Date();

            if (date == 'Invalid Date' || currentDate > date) {
                currentDate = currentDate.getFullYear() + '/' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '/' + ('0' + currentDate.getDate()).slice(-2);
                $('#dwfrm_giftcert_purchase_deliveryDate').val(currentDate);
            }
        });

        $('#AddToBasketButton').off('click').on('click', setAddToCartHandler);
        $('#CheckBalanceButton').off('click').on('click', checkBalance);
    }
};

module.exports = giftCardPage;