'use strict';

var ajax = require('./../../ajax'),
    formPrepare = require('./../checkout/formPrepare'),
    progress = require('./../../../../../app_rleu_core/cartridge/js/progress'),
    tooltip = require('./../../../../../app_rleu_core/cartridge/js/tooltip'),
    giftMessage = require('./giftMessage'),
    util = require('./../../util'),
    dialog = require('./../../dialog'),
    validator = require('./../../validator'),
    shiptoStore = require('./shiptostore'),
    AddressDialog = require('./addressdialog');

var shippingMethods, addressIDonLoad = ''; 
var shippingAddress;

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}
function initializeContactUsForm() {

    var $form = $('#ContactForm');
    $form.find('.input-textarea').addClass('Profanity');
    validator.init();
    $(document).find('#sendBtn', $form).off('click').on('click', function(e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }

        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var sendName = $form.find('#sendBtn').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + sendName + '=x',
            type: 'POST'
        };

        $.ajax(options).done(function(data) {
            $('#dialog-container').html(data);
            initializeContactUsForm();
        });
    });
}
function contactBtn() {
    $('#emailUsCustService').on('click', function(e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializeContactUsForm
            }
        });
    });
}
/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');

    // indicate progress
    if ($('#secondary.summary').length == 0) {
        $summary = $('.cart-right-section-summary');
    }
    progress.show($summary);
    // load the updated summary area
    var url = util.appendParamsToUrl(Urls.summaryRefreshURL, {
    	pagetype: $('div.cart-wrapper').length > 0 ? 'cartPage' : null
    });
    $summary.load(url, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        $('.order-component-block').hide();

        giftMessage.removePromoCode();
        giftMessage.applyCouponCode();
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        }
        $('.mini-summary-btn').html($('.button-fancy-large').val());
        //to disable/enable continue button based on shipping method and form fields
        require.ensure([], function (require) {
            var chunk = require('./formPrepare');
            if (chunk.validateForm) {
                chunk.validateForm();
            }
        }, function (error) {
            console.log('error!', error);
        }, 'checkout');
        if ($(window).width() < 959) {
        	$('.cart-wrapper .free-shipping-progress-moved').remove();
        	$('.cart-wrapper .free-shipping-progress').detach().addClass('free-shipping-progress-moved').appendTo('.cart-actions-top');
        }
        if($('.cart-right-section-summary') && $('.cart-right-section-summary').find('.shipping-bars-cart') && $('.cart-right-section-summary').find('.shipping-bars-cart').html()){
    		$('.mini-cart-content').find('.shipping-bars-cart').html(' ');
    		$('.mini-cart-content').find('.shipping-bars-cart').html($('.cart-right-section-summary').find('.shipping-bars-cart').html());
		}
    });
}

/**
 * @function
 * @description updates the line item of donation
 */
function updateDonationLineItems() {
    var $donationLineItems = $('.donationlineitems');
    progress.show($donationLineItems);
    // load the updated donation line item area
    $donationLineItems.load(Urls.donationLineItemRefreshURL, function() {
    });
}
/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        firstName: $form.find('input[name$="_firstName"]').val(),
        lastName: $form.find('input[name$="_lastName"]').val(),
        companyname: $form.find('input[name$="_companyname"]').val(),
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val() || $form.find('select[name$="_city"]').val(),
        phone: $form.find('input[name$="_phone"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID,ele) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    var upsStore = $('input[name$=_singleshipping_shipToUpsStore]').val();
    // attempt to set shipping method
    var $curEle = ele;
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {
        shippingMethodID: shippingMethodID,
        format : 'ajax'
    });
    
    if ($('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'true') {
    	url = util.appendParamsToUrl(url, {upsStore : true
    	});
    } else if (SitePreferences.SHIPTO_STORE_ENABLED && $('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'false') {
    	url = util.appendParamsToUrl(url, { shipToStore : true
    	});
    }

    ajax.getJson({
        url: url,
        callback: function(data) {
            updateSummary();
            updateShippingMethodList();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            if ($('#shipping-method-list').find('.shipping-method-desc').length > 0 && !SitePreferences.MONETATE_SHIPPING_DELIVERY_DATE) {
            	if (data.shippingMethodEdd!=undefined) {
            	$('#shipping-method-list').find('.shipping-method-desc').html(data.shippingMethodEdd);
            	} else {
            		$('#shipping-method-list').find('.shipping-method-desc').empty();
            	}
            } 
            if($('.multishipping-method-desc').length > 0 && data.shippingMethodEdd!=''){
            	$curEle.closest('.item-details').find('.multishipping-method-desc').html(data.shippingMethodEdd);
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();
        }
    });
}

function getFormAddress() {
	    var $form = $('.address');
	    var address = {
	        firstName: $form.find('input[name$="_firstName"]').val(),
	        lastName: $form.find('input[name$="_lastName"]').val(),
	        companyname: $form.find('input[name$="_companyname"]').val(),
	        address1: $form.find('input[name$="_address1"]').val(),
	        address2: $form.find('input[name$="_address2"]').val(),
	        countryCode: $form.find('select[id$="_country"]').val(),
	        stateCode: $form.find('select[id$="_state"]').val(),
	        postalCode: $form.find('input[name$="_postal"]').val(),
	        city: $form.find('input[name$="_city"]').val() || $form.find('select[name$="_city"]').val(),
	        phone: $form.find('input[name$="_phone"]').val()
	    };
        if (($form.find('select[id$="_country"]').val() === "AU" || $form.find('select[id$="_country"]').val() === "MY") && $form.find('input[id$="citySaved"]').val()) {
            util.updateSuburbOptions($("#dwfrm_singleshipping_shippingAddress"));
        }
		return address;
	}
	
	function addStepTooltip() {
	    $('.step-1, .step-2, .step-3').each(function( index ) {
	    	var $this = $( this );
            var address = getFormAddress();
	    	if($this.find('a').length > 0) {
	    	  if(address !== null && shippingAddress !== null && (JSON.stringify(address) != JSON.stringify(shippingAddress))) {
	    		  if($this.find('.tooltip-content').length == 0) {
	    			  $this.addClass('tooltip');
	    			  $this.append('<div class="tooltip-content" data-layout="small" style="display:none;">' +Resources.STEP_TOOLTIP_MESSAGE+ '</div>');
	    		  }
	    	  } else {
	    		  $this.removeClass('tooltip');
	    		  $this.find('.tooltip-content').remove('.tooltip-content');
	    	  }
	    	}
	    });
	}
	
/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
var indexForShippingMethod = 0;
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        return;
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);
    var upsStore = $('input[name$=_singleshipping_shipToUpsStore]').val();
    if ($('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'true') {
    	url = util.appendParamsToUrl(url, {upsStore : true
    	});
    } else if (SitePreferences.SHIPTO_STORE_ENABLED && $('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'false') {
    	url = util.appendParamsToUrl(url, { shipToStore : true
    	});
    }

    ajax.getJson({
        url: url,
        callback: function(data) {
            if (!data) {
                //window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (false && shippingMethods && shippingMethods.toString() === data.toString()) { // PREVAIL-Added for 'false' to handle SPC.
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);
            
            // Error message for shipping restriction should be hided initially.
            $('.pt_checkout .error-form.no-shipping-method').hide();
            
            // disabled CTA when no any applicable shipping method.            
            if (data.length == 0) {
            	$('.mini-summary-btn').prop('disabled', 'disabled');
            	$('.button-fancy-large').prop('disabled', 'disabled');
                $('.pt_checkout .error-form.no-shipping-method').show();
            } 
            //update summary section.
            // This method is breaking everything. So it is removed.
            // updateSummary();
            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            if ($('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'true') {
            	smlUrl = util.appendParamsToUrl(smlUrl, {upsStore : true
            	});
            } else if (SitePreferences.SHIPTO_STORE_ENABLED && $('.cartHasShipToStoreShipment').val() == 'true' && upsStore == 'false') {
            	smlUrl = util.appendParamsToUrl(smlUrl, { shipToStore : true
            	});
            }
            
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');

                $('body').on('change', 'select.shipping-method-drop', function () {
                    selectShippingMethod($(this).val());
                });

                $('body').on('click', '.monetate-shipping-method', function() {
                    selectShippingMethod($(this).data('value'));
                });

                if (SitePreferences.SHIPTO_STORE_ENABLED && $('.cartHasShipToStoreShipment').val() == 'true' && indexForShippingMethod < 1) {
                	indexForShippingMethod += 1;
                	$('select.shipping-method-drop').trigger('change');
                }

                // update the summary
                progress.hide();
                tooltip.init();

                //if nothing is selected in the shipping methods select the first one
                if (SitePreferences.MONETATE_SHIPPING_DELIVERY_DATE) {
                    if ($shippingMethodList.find('.monetate-shipping-method[selected]').length === 0) {
                        $shippingMethodList.find('.monetate-shipping-method:first').prop('selected', true);
                        if ($shippingMethodList.find('.monetate-shipping-method[selected]').length === 0) {
                            //continue button disabled if no shipping method available
                            $('button[name$="chkoutButton"]').prop("disabled", true);
                            $('button[name$="_shippingAddress_save"]').prop("disabled", true);
                        } else {
                            //to disable/enable continue button based on shipping method and form fields
                            require.ensure([], function (require) {
                                var chunk = require('./formPrepare');
                                if (chunk.validateForm) {
                                    chunk.validateForm();
                                }
                            }, function (error) {
                                console.log('error!', error);
                            }, 'checkout');
                        }
                    }
                } else {
                    if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                        $shippingMethodList.find('.input-radio:first').prop('checked', true);
                        if ($('#shipping-method-drop').val() == '' || $('#shipping-method-drop').val() === undefined || $('#shipping-method-drop').val() == null) {
                            //continue button disabled if no shipping method available
                            $('button[name$="chkoutButton"]').prop("disabled", true);
                            $('button[name$="_shippingAddress_save"]').prop("disabled", true);
                        } else {
                            //to disable/enable continue button based on shipping method and form fields
                            require.ensure([], function (require) {
                                var chunk = require('./formPrepare');
                                if (chunk.validateForm) {
                                    chunk.validateForm();
                                }
                            }, function (error) {
                                console.log('error!', error);
                            }, 'checkout');
                        }
                    }
                }

                contactBtn();
            });
            if($('div.dyn-form-shipping').find('input.is-addressy-select').val() == 'true') {
	            setTimeout(function() {
	            	$('div.dyn-form-shipping .phone').trigger('blur');
		            $('div.dyn-form-shipping').find('input.is-addressy-select').val('false');
	            }, 1000);
            }
            addStepTooltip();
        }
    });
    setTimeout(function() {
        if ($('.button-fancy-large').attr('disabled') != 'undefined' && $('.button-fancy-large').attr('disabled') == 'disabled') {
            $('.mini-summary-btn').prop('disabled', true);
        }
        $('.mini-summary-btn').html($('.button-fancy-large').val());
    }, 1000);
}



function initialize() {

    var address = $('input[name$=_singleshipping_addressList]').data('address');
    var fromedit = $('#shipping-edit').length > -1 ? $('#shipping-edit').val() : null;

    $('.address-select-box input:radio').each(function () {
        if ($(this).is(':checked')) {
            var address = $(this).data('address');
            if (address.countryCode === "AU" || address.countryCode === "MY") {
                if ($('input[id="citySaved"]').length) {
                    $('input[id="citySaved"]').val(address.city);
                }
                $('[name$=_addressFields_country]').val(address.countryCode).trigger('change');
                $('[name$=_addressFields_states_state]').val(address.stateCode).trigger('change');
            }
        }
    });

    $('.checkout-shipping input.postal').on('change',function() {
        var $country = $('#dwfrm_singleshipping_shippingAddress').find('select[id$="_country"]');
        var countryVal = $country.val();
        if (countryVal == 'AU' || countryVal == 'MY') {
            util.updateSuburbOptions($('#dwfrm_singleshipping_shippingAddress'));
        }
    });

    $('body').on('input blur focusout', 'input[id$="_addressFields_address1"]', function(e) {
		var formatdata = $(this).attr('edq-metadata');
		if(formatdata){
			var addressJson = JSON.parse(formatdata);
            if (addressJson.address[6].country == "AUSTRALIA" || addressJson.address[6].country == "MALAYSIA") {
                if ($('input[id="citySaved"]').length) {
                    $('input[id="citySaved"]').val(addressJson.address[3].locality);
                }
                if ($('input[id="experianAddress"]').length) {
                    $('input[id="experianAddress"]').val(formatdata);
                }
                $(this).removeAttr('edq-metadata');
                util.updateSuburbOptions($('#dwfrm_singleshipping_shippingAddress'));
            }
		}
	 });

    shippingAddress = getFormAddress();
    
    // Fixed state from being defaulted to any state if there is no data filled out.
    if (address === undefined && fromedit == null) {
        $('select[name$=_addressFields_states_state]').val('');
    }
    // formPrepare.init({
    //     continueSelector: '[name$="shippingAddress_save"]',
    //     formSelector: '[id$="singleshipping_shippingAddress"]'
    // });
    require.ensure([], function (require) {
        var chunk = require('./formPrepare');
        if (chunk.init) {
            chunk.init({
                continueSelector: '[name$="shippingAddress_save"]',
                formSelector: '[id$="singleshipping_shippingAddress"]'
            });
        }
    }, function (error) {
        console.log('error!', error);
    }, 'checkout');
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change blur focusout click', 'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"], select[name$="_addressFields_city"]', function () { // PREVAIL-Changed ZIP to postal
        if(!$('form.address').hasClass('edit-address-details')) { //For edit address no need to call this function
    		updateShippingMethodList();
    	}
    });

  //Donation custom amount selection
    $('.donation-checkout a.donation-value-link').on('click', function(e) {
    	e.preventDefault();
    	var selectedVal = $(this).text();
    	var url = '';
    	var productVal = $(this).text().match(/[\d\.]+/g).toString();
    	productVal = parseInt(productVal);
    	$('.donationProductVar').val(productVal);
    	var donationVal = $('.donationProductVar').val();
    	$('.donation-value-group li.selected').removeClass('selected');
    	$(this).closest('li.donationVal').addClass('selected');
    	$(this).closest('.donation-value-group').find('span.error').remove();
    	$('input[name$= "purchase_amount"]').removeClass('error').val('');
    	$('input[name$= "purchase_amount"]').trigger('blur');
    	 url = Urls.addDonationProduct;
    	url = util.appendParamsToUrl(url, {
            'donationProductVar': donationVal
        });
    	$.ajax({
            url: url,
            dataType: 'html',
            callback: function() {},
            success: function(data) {
            	updateSummary();
           	 	updateDonationLineItems();
            }
        });
    	 
    });
    
    $('input[name$= "purchase_amount"]').on('focusin' , function(){
    	$('#dwfrm_donation_purchase_amount-error').text('');
		$('.donation-value-group li.selected').removeClass('selected');
		$('.donationProductVar').val('');
	});

    $('body').on('selectmenuchange', 'select.shipping-method-drop', function() {
        var selectedVal = $('select.shipping-method-drop').find('option:selected').attr('data-shipingdescription');
        $(this).siblings('div.shipping-method-desc').text(selectedVal);
    });
    $(window).on('resize',function() {
    	require.ensure([], function (require) {
            var chunk = require('./formPrepare');
            if (chunk.dynFormDisplayStyle) {
                chunk.dynFormDisplayStyle('.dyn-form-shipping');
            }
        }, function (error) {
            console.log('error!', error);
        }, 'checkout');
	});
    $('body').on('click', 'div.show-add-address', function() {
        $('input[name$=_singleshipping_addressList]').prop('checked', false);
        var shippingForm = $('.dyn-form-shipping');
        $('[name$=_shippingAddress_save], .mini-summary-btn').prop('disabled', true);
        shippingForm.show();
        $('div.addToaddrBookShipping').show();
        var $suburb = shippingForm.find('select[id$="_city"]');
        if($suburb.length && shippingForm.closest('form').find('input[id="citySaved"]').length) {
            $suburb.val('').removeClass('valid').removeClass('error');
            $suburb.find('option[value!=""]').each(function() {
                $(this).remove();
            });
        }
        shippingForm.find('input[type=text], [name$=_states_state], input[type=tel]').val('').removeClass('valid').removeClass('error');
        shippingForm.find('.input-wrapper').find('.valid-icon, span.error').remove();
        if (shippingForm.find('.shipto-store-address-details-wrapper').length > 0 && shippingForm.find('.shipto-store-address-details-wrapper').nextAll('div.input-wrapper').is(':visible') == false && $('.cartHasShipToStoreShipment').val() == 'false') {
        	shippingForm.find('.shipto-store-address-details-wrapper').nextAll('div.input-wrapper').show();
        	if ($('.shipto-store-address-details').length > 0) {
        		$('.shipto-store-address-details').hide();
        	}
        	$('#single-address').prop('checked', true);
        }
        $('.show-add-address').hide();
        $('.addess-update-block').addClass('hide');
        shippingForm.closest('form').removeClass('edit-addess-details');
        if ($(this).closest('form').find('.gift-Message-error').length > 0) {
        	$(this).closest('form').find('.gift-Message-error').remove();
        }
        require.ensure([], function (require) {
            var chunk = require('./formPrepare');
            if (chunk.dynFormDisplayStyle) {
                chunk.dynFormDisplayStyle('.dyn-form-shipping');
            }
        }, function (error) {
            console.log('error!', error);
        }, 'checkout');
    });

    //cancel button for shipping address
    $('body').on('click', 'span.shippingaddress-cancel, span.address-update-cancel', function () {
        var $curObj = $(this);
        var hiddenSection = $curObj.closest('form');
        $('div.dyn-form-shipping').hide();
        $('.show-add-address').show();
        if($curObj.hasClass('shippingaddress-cancel')) {
	        $('div.addToaddrBookShipping').hide();
        } else if($curObj.hasClass('address-update-cancel')) {
        	$('.address-update-block').addClass('hide');
        	hiddenSection.removeClass('edit-address-details');
        	util.scrollBrowser(hiddenSection.find(".select-address").offset().top - 10);	//scroll to saved address list once cancel
        }
        var firstAddress = $('.select-address .all-address-lists').find('input:radio:first');
    	if (firstAddress) {
    		firstAddress.prop("checked", true).trigger("change");
    	} else {
    		// formPrepare.validateForm();
            require.ensure([], function (require) {
                var chunk = require('./formPrepare');
                if (chunk.validateForm) {
                    chunk.validateForm();
                }
            }, function (error) {
                console.log('error!', error);
            }, 'checkout');
    	}
        require.ensure([], function (require) {
            var chunk = require('./formPrepare');
            if (chunk.dynFormDisplayStyle) {
                chunk.dynFormDisplayStyle('.dyn-form-shipping');
            }
        }, function (error) {
            console.log('error!', error);
        }, 'checkout');
    });
    $('.address-select-box a.edit-this-address').on('click', function(e) {
    	e.preventDefault();
    	var $form = $('.address');
        var $selected = $(this).closest('.address-select-box').find('input:radio');
        var shippingForm = $('.dyn-form-shipping');
        if (shippingForm.find('.shipto-store-address-details-wrapper').length > 0 && shippingForm.find('.shipto-store-address-details-wrapper').nextAll('div.input-wrapper').is(':visible') == false  && $('.cartHasShipToStoreShipment').val() == 'false') {
	        shippingForm.find('.shipto-store-address-details-wrapper').nextAll('div.input-wrapper').show();
	        if ($('.shipto-store-address-details').length > 0) {
	        	$('.shipto-store-address-details').hide();
	        }
        }
        var address = $selected.data('address');
        $('.current-address-id').val($selected.attr('id'));
        if ((address.countryCode === "AU" || address.countryCode === "MY") && $('input[id="citySaved"]').length) {
            $('input[id="citySaved"]').val(address.city);
        }
        $('input[name$=_addressFields_addressid]').val(address.displayValue);
        $('input[name$=_addressFields_firstName]').val(address.firstName);
        $('input[name$=_addressFields_lastName]').val(address.lastName);
        $('input[name$="_addressFields_companyname"]').val(address.companyname);
        $('input[name$=_addressFields_address1]').val(address.address1);
        $('input[name$=_addressFields_address2]').val(address.address2);
        $('input[name$=_addressFields_postal]').val(address.postalCode);
        $('input[name$=_addressFields_city]').val(address.city);
        $('select[name$=_addressFields_city]').val(address.city);
        $('select[name$=_addressFields_states_state]').val(address.stateCode);
        $('select[name$=_addressFields_country]').val(address.countryCode);
        $('input[name$=_addressFields_phone]').val(address.phone);
        $('div.dyn-form-shipping').removeClass('hide').show();
        $('div.dyn-form-shipping .firstName').trigger('blur');
        $form.addClass('edit-address-details');
        if($('div.addToaddrBookShipping').is(':visible')) {
        	$('div.addToaddrBookShipping').addClass('hide').hide();
        }
        $('.show-add-address').hide();
        $form.find('.address-update-block').removeClass('hide');
        $('[name$="_shippingAddress_save"], .mini-summary-btn').prop('disabled', true);
        if(!$('.address-select-box').find('input:radio').is(':checked')) {
        	 $('.button-fancy-large, .mini-summary-btn').prop('disabled', true);
        }
        util.scrollBrowser($(".dyn-form-shipping").offset().top - 10); //scroll to edit form
        $(document).trigger('checkout:afterClickEditAddress', {'address': address});
        util.updateSuburbOptions($("#dwfrm_singleshipping_shippingAddress"));
    });
    //Updating shipping address
    $('.address-update-block .update-address').off('click').on('click', function(e) {
    	e.preventDefault();
    	if(!$('.dyn-form-shipping').find('input, select').valid()) {
    		return false;
    	}
    	progress.show($('#container-wrap'));
    	var curAddressId = $('.current-address-id').val();
    	var addressForm = $("form.address");
        if ($('input[id="citySaved"]').length) {
            $('input[id="citySaved"]').val('');
        }
        $.ajax({
            type: 'POST',
            url: util.appendParamToURL(Urls.editshippingaddress, 'format', 'ajax'),
            data: $(".dyn-form-shipping select, .dyn-form-shipping input").serialize() + '&' + 'curAddressId=' + curAddressId,
            success: function(response) {
            	$('.select-address').find('.field-wrapper.all-address-lists').html(response);
            	initialize();
            	$('div.dyn-form-shipping').addClass('hide').hide();
            	$('.address-update-block').addClass('hide');
            	$('.show-add-address').show();
            	addressForm.removeClass('edit-address-details');
            	util.scrollBrowser(addressForm.find(".select-address").offset().top - 20);	//scroll to saved address list once update success
            }
        });
    });
	$('body').on('change', '#ecoFriendly', function() {
		var ecoCheckbox = $(this);
		if (ecoCheckbox.is(':checked')) {
			ecoCheckbox.prop('value', 'true');
		} else {
			ecoCheckbox.prop('value', 'false');
		}
	});

    giftMessageBox();
    updateShippingMethodList();
    setTimeout(function () {
        giftMessage.giftMessage();
    }, 2000);
    initializeContactUsForm();

    //trigger validation on click outside shipping form
    $('.dyn-form-shipping').on('focusout', function (event) {
        setTimeout( function() {
            var $forminputs = $('.dyn-form-shipping').find(':focus');
   
                if ($forminputs.length) {
                    return; 
                } else {
                    require.ensure([], function (require) {
                        var chunk = require('./formPrepare');
                        if (chunk.validateAllRequired) {
                            chunk.validateAllRequired();
                        }
                    }, function (error) {
                        console.log('error!', error);
                    }, 'checkout');
                }
        }, 1000);
    });
    
    // trigger events on enter
    $('body').on('keydown', '[name$="_couponCode"]', function(e) {
        if ($('[name$="_couponCode"]').closest('.field-wrapper').find('.coupon').length > 0) {
            $('[name$="_couponCode"]').closest('.field-wrapper').find('.coupon').remove();
        }
        if (e.which === 13) {
            e.preventDefault();
            $('#add-coupon').trigger('click');
        }
    });

    $('.address-select-box input[name$="_singleshipping_addressList"]').on('change click', function() {
        var $curObj = $(this);
        var hiddenSection = $curObj.closest('form');
        var address = $(this).data('address');
        if ((address.countryCode === "AU" || address.countryCode === "MY") && $('input[id="citySaved"]').length) {
            $('input[id="citySaved"]').val(address.city);
        }
        hiddenSection.find('div.dyn-form-shipping').addClass('hide');
        hiddenSection.find('div.addToaddrBookShipping').addClass('hide');
        $('.ship-to-store').prop('checked', false);  
        $('.cartHasShipToStoreShipment').val('false');
        $('.show-add-address').show();
        $('.address-update-block').addClass('hide');
        hiddenSection.removeClass('edit-address-details');
        require.ensure([], function (require) {
            var chunk = require('./formPrepare');
            if (chunk.validateForm) {
                chunk.validateForm();
            }
        }, function (error) {
            console.log('error!', error);
        }, 'checkout');
        selectShippingMethod($('select.shipping-method-drop').val());
        var address = $curObj.data('address');
        if ((address.ID).length > 20 || (address.phone).length > 15) {
        	addressIDonLoad = address.ID;
        	$('.address-select-box a.edit-this-address').trigger('click');
        }
        $(document).trigger('checkout:afterClickEditAddress', {'address': address});
    });

    $('.single-multi-addresses input:radio').on('change',function () {
        $(this).closest('form').find('.ship-to-single').find('button.shiptosinglebutton').trigger('click');
    });

    $('.single-shipping-addresses-sec input:radio').on('change',function () {
    	if ($(this).hasClass('multi-address')) {
    		$(this).closest('form').find('.ship-to-multiple').find('button.shiptomultiplebutton ').trigger('click');
    	} else if ($(this).hasClass('single-address')) {
    		var url = util.appendParamsToUrl(Urls.shipping,{
    			changeS2SToShipping : true
    		});
    		location.href = url
    	}
    });

    $('.checkout-shipping').on('submit', function() {
        if($('.donationProductVar').val()) {
            $('.button-fancy-large').attr('disabled', false)
        }
    });

    $('.select-address input:radio').on('change',function () {
        var $form = $('.address');
        var $selected = $(this);
        var selectedAddress = $selected.data('address');
        if (!selectedAddress) {
            return;
        }
        $form.removeClass('edit-address-details');
        if ((selectedAddress.ID).length < 20 && (selectedAddress.phone).length < 15) {
        	$('.address-update-block').addClass('hide');
        }
        $('input[name$=_addressFields_addressid]').val(selectedAddress.displayValue);
        util.fillAddressFields(selectedAddress, $form);
        updateShippingMethodList();
        updateSummary();
        if (selectedAddress.phone == null) {
            $form.addClass('edit-address-details');
            $('.current-address-id').val($selected.attr('id'));
            $('.dyn-form-shipping').removeClass('hide').show();
            $('.dyn-form-shipping').css('display', 'block');
            $('.address-update-block').removeClass('hide');
            $('.show-add-address').hide();
            $('div.addToaddrBookShipping').hide();
            $('[name$="_shippingAddress_save"], .mini-summary-btn').prop('disabled', true);
            $form.find('.address-update-block').removeClass('hide');
            // formPrepare.dynFormDisplayStyle('.dyn-form-shipping');
            require.ensure([], function (require) {
                var chunk = require('./formPrepare');
                if (chunk.dynFormDisplayStyle) {
                    chunk.dynFormDisplayStyle('.dyn-form-shipping');
                }
            }, function (error) {
                console.log('error!', error);
            }, 'checkout');
        }
    });

    giftMessage.removePromoCode();
    giftMessage.applyCouponCode();
    $('input[name$=_singleshipping_addressList]').on('click',function () {
        $('.dyn-form-shipping').hide();
        $('.dyn-form-shipping').css('display','');
        $('div.addToaddrBookShipping').hide();
        $('div.addToaddrBookShipping').hide();
    });
    if (('.checkout-header').length > 0) {
        $(window).on('load',function () {
            if (!($('input[name$=_singleshipping_addressList]').is(':checked'))) {
                $('.dyn-form-shipping').show();
                $('div.addToaddrBookShipping').show();
                $('div.addToaddrBookShipping').show();
                if ($(window).width() > 959) {
                    $('.dyn-form-shipping').css('display','flex');
                }

                var address = $('.address-select-box .address-nickname .default').closest('.address-select-box').find('input').data('address');
                if (address) {
                    $(document).trigger('checkout:afterClickEditAddress', {'address': address});
                }
            } else {
                var addressdata = $('.address-select-box input:radio:checked').data('address');
                if (addressdata.phone == null) {
                    $form.addClass('edit-address-details');
                    $('.current-address-id').val($selected.attr('id'));
                    $('.dyn-form-shipping').removeClass('hide').show();
                    $('.dyn-form-shipping').css('display', 'block');
                    $('.address-update-block').removeClass('hide');
                    $('.show-add-address').hide();
                    $('div.addToaddrBookShipping').hide();
                    $('[name$="_shippingAddress_save"], .mini-summary-btn').prop('disabled', true);
                    $form.find('.address-update-block').removeClass('hide');
                    require.ensure([], function (require) {
                        var chunk = require('./formPrepare');
                        if (chunk.dynFormDisplayStyle) {
                            chunk.dynFormDisplayStyle('.dyn-form-shipping');
                        }
                    }, function (error) {
                        console.log('error!', error);
                    }, 'checkout');
                } else {
                    $('.dyn-form-shipping').hide();
                    $('.dyn-form-shipping').css('display', '');
                    $('div.addToaddrBookShipping').hide();
                }
            }

            var phone = $('input[name$=_addressFields_phone]').val();

            var $address = $('.address-select-box input:radio:checked').length > 0 ? $('.address-select-box input:radio:checked') : $('.address-select-box input:radio:first');
            var addressID = $('input[name$=_addressFields_addressid]').val();
            if((addressID && addressID.length > 20) || (phone && phone.length > 15)){
            	addressIDonLoad = addressID;
            	$address.closest('.address-select-box').find('a.edit-this-address').trigger('click');
            }
        });
        updateSummary();
    }
    $(document).on('change', 'input[name$=_addressFields_addressid]',function() {
    	var addressID = $('input[name$=_addressFields_addressid]').val();
    	addressIDonLoad = addressID;
    	if(addressID.length > 20){
    		$('.shipping-address-click').prop('disabled',true);
    		$('button.mini-summary-btn').prop('disabled',true);
    	} else {
    		$('.shipping-address-click').prop('disabled',false);
    		$('button.mini-summary-btn').prop('disabled',false);
    	}
    });
    document.cookie = 'openbillingForm' + '=hideBillingForm';
    
    if (($('.addressvalidtion-paypal').val() != '' && $('.addressvalidtion-paypal').val() == 'false') || ($('.applePayError') && $('.applePayError').length > 0)) {
    	$('.address').validate().form();
    	$('button[name$="chkoutButton"]').prop("disabled", true);
		$('button[name$="_shippingAddress_save"]').prop("disabled", true);
    }
}

exports.init = function () {
	initialize();
	if(SitePreferences.SHIPTO_STORE_ENABLED) {
		shiptoStore.init();
	}
    //Address validation
    var shippingAddressValidFlag = false;
    $('body').off('click', '.shipping-address-click').on('click', '.shipping-address-click', function(e) {
    	
    	if ($('.cartHasShipToStoreShipment').val() == 'true') {
    		shippingAddressValidFlag = true;
    	}
        if (!shippingAddressValidFlag && isExperianEnabled) {
            e.preventDefault();
            if (!e.detail || e.detail == 1) {
                var $this = $(this);
                $this.prop("disabled", true);
                var btnMiniSummary = $('#mini-summary-btn');
                if (btnMiniSummary.length) {
                    btnMiniSummary.prop("disabled", true);
                }
                var $form = $('#dwfrm_singleshipping_shippingAddress');
                var params = {
                    firstName: $form.find('input[name$="_firstName"]').val(),
                    lastName: $form.find('input[name$="_lastName"]').val(),
                    address1: $form.find('input[name$="_address1"]').val(),
                    address2: $form.find('input[name$="_address2"]').val(),
                    countryCode: $form.find('select[id$="_country"]').val(),
                    stateCode: $form.find('input[id$="_state"]').val() || $form.find('select[id$="_state"]').val(),
                    postalCode: $form.find('input[name$="_postal"]').val(),
                    city: $form.find('input[name$="_city"]').val() || $form.find('select[name$="_city"]').val(),
                    phone: $form.find('input[name$="_phone"]').val()
                };
                var params = JSON.stringify(params);
                var url = util.appendParamsToUrl(Urls.addressBatchCleansing, {
                    'params': params
                });
                var formLabel=''; 
                if ($form.find('select[id$="_state"]').val() == 'AE' || $form.find('select[id$="_state"]').val() == 'AU') {
                    formLabel=$form.find('select[id$="_state"] option:selected').attr('label');
                }
                $.ajax({
                    type: 'POST',
                    url: url,
                    dataType: 'html',
                    success: function(response) {
                        $this.prop("disabled", false);
                        btnMiniSummary.prop("disabled", false);
                        if ($(response).find('#EditAddressForm').length > 0) {
                            var form = $(response).find('#EditAddressForm');
                            var dlg = dialog.create({
                                target: "#Edit-form-addr-dialog",
                                options: {
                                    dialogClass: 'address-invalid',
                                    width: 700,
                                    open: function() {
                                        validator.init();
                                        var $editAddrForm = $('body').find('#EditAddressForm');
                                        new AddressDialog($form, $editAddrForm, formLabel).init();
                                    },
                                    close: function() {
                                        shippingAddressValidFlag = false;
                                        $(this).dialog('close');
                                        $(dlg).remove();
                                    }
                                }
                            });
                            $(dlg).html('').append(form);
                            $(dlg).dialog('open');
                            if ($form.find('select[id$="_country"]').val() == "AU" || $form.find('select[id$="_country"]').val() == "MY") {
                                $('#EditAddressForm').find('input[id="citySaved"]').val($form.find('select[name$="_city"]').val());
                            }
                            $('#EditAddressForm').find('input[name$="_shippingaddress_address2"]').val($form.find('input[name$="_address2"]').val() || "");
                            $('#EditAddressForm input.postal').on('change',function() {
                                var $country = $('#EditAddressForm').find('[id$="_country"]');
                                var countryVal = $country.val();
                                if (countryVal == 'AU' || countryVal == 'MY') {
                                    util.updateSuburbOptions($('#EditAddressForm'));
                                }
                            });
                            $('.has-float-label-apac').has('label').addClass('transparent-placeholder');
                            $('#EditAddressForm').find('input[name$="_postal"]').trigger('change');
                        } else {
                            shippingAddressValidFlag = true;
                            $('button[name$="_singleshipping_shippingAddress_save"]').removeClass('shipping-address-click');
                            $('button[name$="_singleshipping_shippingAddress_save"]').trigger("click");
                        }
                    }
                });
            }
        } else {
				e.preventDefault();
				if ($('.cartHasShipToStoreShipment').val() == 'true') {
					var storeID = $('.store-id').val();
		        	var url = Urls.shipToStoreBilling
		        	url = util.appendParamsToUrl(url, {
		        		storeID : storeID
		            });
		        	$.ajax({
		                type: 'POST',
		                url: url,
		                success: function(data) {
		                	$('button[name$="_singleshipping_shippingAddress_save"]').removeClass('shipping-address-click');
	                        $('button[name$="_singleshipping_shippingAddress_save"]').trigger("click");
		                }
		            });
				} else {
					$('button[name$="_singleshipping_shippingAddress_save"]').removeClass('shipping-address-click');
	                $('button[name$="_singleshipping_shippingAddress_save"]').trigger("click");
				}
        }
    });
};



exports.selectShippingMethod = selectShippingMethod;
exports.updateShippingMethodList = updateShippingMethodList;
exports.updateSummary = updateSummary; //JIRA PREV-99 : shipping methods is not displayed for 2nd address in right nav.
exports.initializeContactUsForm = initializeContactUsForm;
exports.contactBtn = contactBtn;