'use strict';
var util = require('../../../../app_apac_foundation/cartridge/js/util');

var giftMessage = {
    init: function () {
        $('body').off('focusout keyup', '.restrict-emoji input');

        setTimeout(function () {
            $('.this-gift-message .purchase-giftmessage').off('focusout');

            $('.this-gift-message .purchase-giftmessage').on('focusout', function(e) {
                e.preventDefault();
                var flagValid = true;

                if ($(this).closest('.this-gift-message').find('.save-this-gift-message').length > 0) {
                    var url = $(this).closest('.this-gift-message').find('.save-this-gift-message').attr('href');
                    var attributeName = '';
                    if ($(this).hasClass('purchase-greeting')) {
                        attributeName = 'greeting';
                        flagValid = profanityCheck($(this).val(),$(this));
                    } else if ($(this).hasClass('purchase-message1')) {
                        attributeName = 'message1';
                        flagValid = profanityCheck($(this).val(),$(this));
                    } else if ($(this).hasClass('purchase-message2')) {
                        attributeName = 'message2';
                        flagValid= profanityCheck($(this).val(),$(this));
                    } else if ($(this).hasClass('purchase-sender')) {
                        attributeName = 'sender';
                        flagValid = profanityCheck($(this).val(),$(this));
                    }
                    if (attributeName.length > 0) {
                        url = util.appendParamsToUrl(url, {
                            'giftMessageVal': $(this).val(),
                            'customAttributename': attributeName
                        });
                        if (flagValid) {
                            $.ajax({
                                url: url
                            });
                        }
                    }
                }
            });
        }, 2000);
    }
};

function profanityCheck(value, $this) {

    var profanityWords = SitePreferences.PROFANITY_WORDS;
    var profan = SitePreferences.PROFANITY_WORDS;

    if (profanityWords == undefined || profanityWords == 'null' || profanityWords == '' || value == '') {
        $this.closest('.gift-field').find('.gift-Message-error').remove();
        $this.removeClass('customerror');
        if ($('.gift-Message-error').length == 0 && $this.closest('form').valid()) {
            $('button[name$="chkoutButton"]').prop("disabled", false);
            $('button[name$="_shippingAddress_save"]').prop("disabled", false);
        }
        return true;
    }
    profanityWords = profanityWords.split('|');
    var valuecheck = value.toLowerCase().split(' ');
    for (var entval in valuecheck) {
        var profanArray = [];
        var entstr = valuecheck[entval].trim();
        for (var i = 0; i < profanityWords.length; i++) {
            var valData = profanityWords[i];
            if (entstr == valData) {
                $this.addClass('customerror');
                $this.closest('.gift-field').find('.gift-Message-error').remove();
                $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                $('button[name$="chkoutButton"]').prop('disabled', true);
                $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                return false;
            }

            if (profan.indexOf(entstr) >= 0) {
                var index1 = profan.indexOf(entstr);
                var indexlast = profan.lastIndexOf('|', index1);
                var index2 = profan.indexOf('|', index1);
                var profanity = profan.substring(indexlast + 1, index2);
                var sentence = '';

                if (profan.split(' ').length > 1) {
                    var loopindx = Number(entval) + profanity.split(' ').length;
                    for (var j = Number(entval); j < loopindx; j++) {
                        var check = valuecheck[j];
                        if (check != undefined && check != '') {
                            if (sentence.length == 0) {
                                sentence = check.trim();
                            } else {
                                sentence = sentence + ' ' + check.trim();
                            }
                        }
                        if (check == '') {
                            loopindx++;
                        }
                    }
                    if (valData == sentence) {
                        $this.addClass('customerror');
                        $this.closest('.gift-field').find('.gift-Message-error').remove();
                        $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                        $('button[name$="chkoutButton"]').prop('disabled', true);
                        $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                        return false;
                    }
                }
            }
            if (valData.split(' ').length > 1) {
                var profanword = valData.split(' ')[0];
                if (profanword == entstr) {
                    profanArray.push(valData);
                }
            }

        }
        if (profanArray.length > 0) {
            for (var k = 0; k < profanArray.length; k++) {
                var sentence1 = '';
                var profanityword = profanArray[k];
                var loopindx = Number(entval) + profanityword.split(' ').length;
                for (var l = Number(entval); l < loopindx; l++) {
                    var check = valuecheck[l];
                    if (check != undefined && check != '') {
                        if (sentence1.length == 0) {
                            sentence1 = check.trim();
                        } else {
                            sentence1 = sentence1 + ' ' + check.trim();
                        }
                    }
                    if (check == '') {
                        loopindx++;
                    }
                }
                if (profanityword == sentence1) {
                    $this.addClass('customerror');
                    $this.closest('.gift-field').find('.gift-Message-error').remove();
                    $this.closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
                    $('button[name$="chkoutButton"]').prop('disabled', true);
                    $('button[name$="_shippingAddress_save"]').prop('disabled', true);
                    return false;
                }

            }
        }
    }
    $this.closest('.gift-field').find('.gift-Message-error').remove();
    $this.removeClass('customerror');
    if ($('.gift-Message-error').length == 0 && $this.closest('form').valid()) {
        $('button[name$="chkoutButton"]').prop("disabled", false);
        $('button[name$="_shippingAddress_save"]').prop("disabled", false);
    }

    return true;
};

module.exports = giftMessage;
