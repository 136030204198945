

'use strict';
var happyTalkApp = {
    init: function () {
        $('#KakaoProfileForm button[type="submit"]').on('click', function() {
            if( $('#KakaoProfileForm').valid()) {
                $('#KakaoProfileForm button[type="submit"]').prop('disabled', true);
                $('#KakaoProfileForm').append(`<input type="hidden" name="${$(this).attr('name')}" value="" />`);
                $('#KakaoProfileForm').submit()
            } 
        })
        
        $('#KakaoAuthenticationForm button[type="submit"]').on('click', function() {
            if( $('#KakaoAuthenticationForm').valid()) {
                $('#KakaoAuthenticationForm button[type="submit"]').prop('disabled', true);
                $('#KakaoAuthenticationForm').append(`<input type="hidden" name="${$(this).attr('name')}" value="" />`);
                $('#KakaoAuthenticationForm').submit()
            } 
        })
        
        $( document ).ready(function() {
            if( $('#KakaoProfileForm input.phone') && $('#KakaoProfileForm input.phone').val() ) {
                $('#KakaoProfileForm input.phone').trigger('blur');
            }
        })
    }
};

module.exports = happyTalkApp;
